main.catalog h1{
	margin-top: 24px;
	margin-bottom: 22px;
}
main:not(.main) #main-search-input{
	width: 686px;
}
.main-input-wrapper + span,
.main-input-wrapper span{
	font-size: 12px;
	line-height: 1.5;
	color: #000000;
}
.ask-price{
	padding: 9px 36px 8px 20px;
	background-color: #fffaea;
	color: #000000;
}
.ask-price h2{
	font-size: 16px;
  	font-weight: bold;
  	line-height: 1.5;
  	margin: 0;
}
.ask-price p{
	font-size: 14px;
  	line-height: 1.5;
  	color: #000000; 	
}
.ask-price #ask-price{
	font-size: 14px;
	font-weight: 600;
	line-height: 2.57;
	letter-spacing: 1.5px;
	padding: 0 16px;
	border: solid 2px #ffc93b;
	background-color: transparent;
	text-transform: uppercase;
}
main.catalog .catalog-links{
	margin-top: 36px;
}
main.catalog .catalog-links h2{
	font-size: 16px;
	font-weight: bold;
	line-height: 1.5;
	color: #000000;
	margin-top: 0;
	margin-bottom: 2px;
}
main.catalog .catalog-links a{
	color: #000000;
}
main.catalog .catalog-links .col-lg-3 > div + div{
	margin-top: 9px;
}
main.catalog .sales-subscript-form-wrapper{
	margin-top: 68px;
}
.win.zapros .form-wrapper{
	padding: 28px 36px 0;
}
.win.zapros h2{
	margin-top: 0;
	margin-bottom: 7px;
	color: #000000;
	font-size: 20px;
  	font-weight: bold;
  	line-height: 1.2;
}
.win.zapros p{
	font-size: 14px;
  	line-height: 1.5;
  	color: #000000;
  	margin-bottom: 31px;
}
.win.zapros span.fieldname{
	display: inline-block;
	width: 96px;
	margin-right: 24px;
	line-height: 1.29;
	color: #000000;
	vertical-align: top;
	margin-top: 10px;
}
.win.zapros form > div + div{
	margin-top: 18px;
}
.win.zapros form input{
	width: 280px;
}
#dropZone{
	width: 280px;
	border: 2px dashed #ffc93b;
	display: inline-block;
	text-align: center;
	padding-top: 15px;
}
#dropZone span{
	display: block;
}
#dropZone a,
#dropZone .drug{
	font-size: 14px;
  	line-height: 1.5;
  	color: #000000;
  	cursor: pointer;
}
#dropZone .size{
	font-size: 10px;
  	line-height: 2.1;
  	color: #000000;
  	margin-top: 2px;
}
#dropZone .loaded-files{
	margin-top: 12px;
}
#dropZone .file-line{
	text-align: left;
	padding: 5px 0 0 10px;
	background-color: #fff3d1;
}
#dropZone .file-line + .file-line{
	padding-top: 0;
}
#dropZone .file-line span{
	display: inline-block;
}
#dropZone .file-name{
    display: inline-block;
    width: 190px;
    overflow: hidden;
    position: relative;
}
#dropZone .file-name:after{
	opacity: 0.9;
	background-image: linear-gradient(to left, #fff1cc, rgba(255, 241, 204, 0));
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	width: 53px;
	height: 18px;
}
#dropZone .file-name span{
	width: 10000px;
	font-size: 12px;
	line-height: 1.5;
	color: #000000;
}
#dropZone .delete-file{
	display: inline-block;
    color: #ff0000;
    vertical-align: top;
    cursor:pointer;
    font-size: 12px;
	line-height: 1.5;
	color: #f52700;
}
.win .popup-footer{
	background-color: rgba(255,250,234, 0.9);
	padding-bottom: 26px;
	padding-left: 156px;
	margin-top: 24px;
}
.win .popup-footer span{
	font-size: 12px;
  	line-height: 1.5;
  	color: #000000;
  	display: block;
  	margin-top: 10px;
  	color: #000000;
}
.win.zapros h2.success{
	font-size: 36px;
  	font-weight: 600;
  	line-height: 1.22;
	color: #00bc66;
	position: relative;
	display: inline-block;
	margin-top: 80px;
	margin-bottom: 10px;
}
.win.zapros h2.success + p{
	font-size: 16px;
	line-height: 1.5;
	color: #000000;
	margin-bottom: 19px;
}
.yellow-transparent{
	font-size: 14px;
	font-weight: 600;
	line-height: 2.57;
	letter-spacing: 1.5px;
	padding: 0 20px;
	border: solid 2px #ffc93b;
	background-color: transparent;
}
.win.zapros.success{
	padding-bottom: 149px;
}
@media (min-width: 1200px){
	.ask-price p{
		width: 70%;
    	display: inline-block;
	}
	.ask-price #ask-price{
		float: right;
	}
	.ask-price{
		width: 66%;
	}
	main.catalog .second-text-block .col-lg-3:after{
		position: absolute;
		z-index: -1;
		content: "";
		opacity: 0.5;
		background-image: url(../img/engine__yellow.png);
		width: 776px;
		height: 591px;
		top: 0;
		right: -152%;
	}
}
@media (min-width: 768px){
	.popup .win.zapros{
		width: 520px;
	}
	#dropZone .load-xs{
		display: none;
	}

}
@media (max-width: 1199px){
	.ask-price{
		margin-top: 20px;
		padding-bottom: 24px;
	}
	main:not(.main) #main-search-input{
		width: 567px;
	}
}
@media (max-width: 767px){
	.ask-price{
		padding-bottom: 36px;
	}
	main:not(.main) #main-search-input{
		width: 235px;
	}
	main.catalog .tractor-models{
		margin-top: 23px;
	}
	main.catalog .ask-price{
		margin-top: 30px;
	}
	main.catalog .catalog-links{
		margin-top: 30px;
	}
	main.catalog .catalog-links .col-lg-3 + .col-lg-3{
		margin-top:  9px;
	}
	main.catalog .sales-subscript-form-wrapper{
		margin-top: 62px;
	}
	.win.zapros{
		min-height: 645px;
	}
	.win.zapros .form-wrapper,
	.win.zapros .popup-footer{
		padding-left: 18px;
		padding-right: 18px;
	}
	.win.zapros .popup-footer{
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		padding-bottom: 46px;
	}
	.win.zapros form input,
	.win.zapros .yellow-btn{
		width: 100%;
	}
	.win.zapros p{
		margin-bottom: 24px;
	}
	.win.zapros span.fieldname{
		margin-bottom: 4px;
	}
	#dropZone{
		padding-top: 24px;
	}
	#dropZone .loaded-files{
		min-height: 30px;
	}
	#dropZone .drug{
		display: none;
	}
}