main.order h1{
	margin-top: 40px;
	margin-bottom: 22px;
}
.order-list .table-header,
.order-list .detail-line{
	font-size: 0;
}
.order-list .table-header > div{
	font-size: 16px;
	font-weight: bold;
	line-height: 1.5;
	color: #000000;
}
.order-list .table-header .quantity{
	padding-left: 23px;
}
.order-list .table-header .price{
	position: relative;
}
.order-list .table-header .price:after{
	background-image: url("../img/₽.svg");
	width: 9px;
	height: 12px;
	content: '';
	position: absolute;
	top: 6px;
	right: -10px;
}
.order-list .table-header{
	border-bottom: 1px solid #000000;
	margin-bottom: 10px;
}
.order-list .detail-line + .detail-line{
	margin-top: 13px;
}
.order-list .detail-line div:not(.name-price){
	font-size: 16px;
}
.order-list .number{
	width: 40px;
	display: inline-block;
	vertical-align: top;
}
.order-list .code{
	width: 140px;
}
.order-list .name{
	width: 203px;
}
.order-list div.count{
	width: 130px;
}
.order-list span.count,
.order-list span.count + span{
	display: none;
}
.order-list .quantity{
	width: 155px;
}
.order-list .cart .quantity{
	font-size: 0;
}
.order-list .cart .price{
	width: 95px;
}
.order-list .quantity button{
	border: none;
	background-color: transparent;
	vertical-align: top;
	padding: 0;
}
.order-list .quantity button img{
	width: 24px;
	height: 24px;
}
.order-list .quantity input{
	width: 56px;
	height: 24px;
	border: solid 2px #ffc93b;
	font-size: 16px;
	padding: 0 8px;
	border-radius: 0;
}
.order-list .delete{
	border: none;
	background-color: transparent;
	padding: 0;
	color: #f52700
}
.order-list .delete span{
	font-size: 16px;
}
main.order .text-doc{
	line-height: 1.5;
	color: #000000;
	margin-top: 37px;
}
main.order #zapros{
	width: 520px;
	background-color: #fffaea;
	margin-top: 41px;
}
main.order #zapros.error{
	background-color: #ffffff;
}
.increment:disabled{
	opacity: 0.3;
}
.empty-cart{
	border: dashed 2px #ffc93b;
	width: 540px;
	margin: 0 auto;
	text-align: center;
	padding: 35px 50px 84px;
	margin-top: 100px;
}
.empty-cart h2{
	font-size: 24px;
	font-weight: bold;
	line-height: 1.33;
	color: #ffc93b;
	margin-bottom: 11px;
}
.empty-cart p{
	font-size: 16px;
	line-height: 1.5;
	color: #000000;
	margin-bottom: 30px;
}
.empty-cart .yellow-btn{
	border: none;
}
.empty-cart a.yellow-btn{
	display: inline-block;
}
main.order #zapros h2.error{
	color: #f52700;
}
main.order #zapros.success{
	margin: 0 auto;
	background-color: #ffffff;
	text-align: center;
}
@media (min-width: 1200px){
	.order-list .delete img{
		display: none;
	}
	.order-list .table-header{
		width: 830px;
	}
}
@media (min-width: 768px){
	.order-list .cart div{
		display: inline-block;
		vertical-align: top;
	}
	.empty-cart .yellow-btn + .yellow-btn{
		margin-left: 30px;
	}
}
@media (max-width: 1199px) and (min-width: 768px){
	.order-list span.count,
	.order-list span.count + span{
		display: inline-block;
	}
	.order-list .delete span{
		display: none;
	}
	main.order #zapros{
		margin-top: 46px;
	}
}
@media (max-width: 1199px){
	.order-list div.count{
		display: none;
	}
	.order-list .number{
		width: 30px;
	}
	.order-list .code{
		width: 130px;
	}
	.order-list .name{
		width: 250px;
	}
	.order-list .quantity{
		width: 140px;
	}
}
@media (max-width: 767px){
	main.order h1{
		margin-top: 35px;
		margin-bottom: 13px;
	}
	.order-list .detail-line{
		padding-bottom: 4px;
	}
	.order-list .delete img,
	.order-list .detail-line .quantity button{
		display: none;
	}
	.order-list .detail-line .name-price,
	.order-list .detail-line .name-price > div{
		margin-top: 3px;
	}
	.order-list .detail-line .number,
	.order-list .detail-line .code,
	.order-list .detail-line .name,
	.order-list .detail-line .count,
	.order-list .detail-line .quantity,
	.order-list .detail-line .price,
	.order-list .delete{
		padding-left: 157px;
		position: relative;
	}
	.order-list .detail-line .number:before,
	.order-list .detail-line .code:before,
	.order-list .detail-line .name:before,
	.order-list .detail-line .count:before,
	.order-list .detail-line .quantity:before,
	.order-list .detail-line .price:before{
		position: absolute;
		top: 0;
		left: 0;
		font-weight: bold;
	}
	.order-list .detail-line .number:before{
		content: '№';
	}
	.order-list .detail-line .code:before{
		content: 'Код';
	}
	.order-list .detail-line .name:before{
		content: 'Наименование';
	}
	.order-list .detail-line .count:before{
		content: 'Комплект';
	}
	.order-list .detail-line .quantity:before{
		content: 'Количество';
	}
	.order-list .detail-line .price:before{
		content: 'Цена, ₽';
	}
	.order-list .detail-line .price{
		right: auto !important;
		transform: none;
	}
	.order-list .detail-line div:not(.name-price),
	.order-list .detail-line a,
	.order-list .detail-line input{
		font-size: 14px;
		line-height: 1.5;
	}
	.order-list .detail-line input{
		line-height: 1.45;
		height: auto;
	}
	.order-list .detail-line div.count{
		display: block;
	}
	.order-list .delete{
	    margin-top: 14px;
	}
	.order-list .delete span{
		text-transform: uppercase;
	    line-height: 1.71;
	    font-size: 14px;
	    letter-spacing: 1.5px;
	    font-weight: 600;
	}
	main.order #zapros{
		margin-top: 28px;
		width: auto;
	}
	/*main.order #zapros:not(.error){
		margin-left: -18px;
		margin-right: -18px;
	}*/
	main.order .win.zapros{
		min-height: auto;
	}
	main.order .win.zapros .popup-footer{
		position: relative;
	}
	.empty-cart{
		width: auto;
		margin-top: 29px;
		padding: 24px 18px 48px;
	}
	.empty-cart .yellow-btn{
		width: 100%;
	}
	main.order #zapros.success .form-wrapper{
		padding: 0;
	}
	main.order #zapros.success h2{
		font-size: 24px;
		line-height: 1.33;
	}
	main.order #zapros.success h2:after{
		background-image: url('../img/icon-order-success-small.svg');
		height: 23px;
		width: 24px;
		right: -30px;
	}
	main.order #zapros.success p{
		font-size: 14px;
	}
}