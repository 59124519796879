*{
	font-family: 'Open Sans', sans-serif;
	outline: none !important;
}
body{
	min-width: 280px;
	-webkit-font-smoothing: subpixel-antialiased;
	-moz-osx-font-smoothing: subpixel-antialiased;
}
header,
main,
footer{
	position: relative;
}
main{
	padding-bottom: 170px;
}
.overlay-back{
	position   : absolute;
    top        : 0;
    left       : 0;
    width      : 100%;
    height     : 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index    : 5;
    display    : none;
}
.overlay-back-popup{
	position   : absolute;
    top        : 0;
    left       : 0;
    width      : 100%;
    height     : 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index    : 500;
    display    : none;
}
a:hover,
a:focus{
	text-decoration: none;
	color: inherit;
}
.solid{
	border-bottom: 1px solid rgba(0,0,0,0.3);
}
.dashed{
	border-bottom: 1px dashed rgba(0,0,0,0.3);
}
.solid:hover,
.dashed:hover{
	border-color: #000000;
}
ul{
	list-style: none;
	padding: 0;
	margin: 0;
}
button:active{
	box-shadow: none !important;
}
input{
	box-sizing: border-box;
	-webkit-appearance: none;
	color: #000000;
}
input::-webkit-input-placeholder{
	color: #888888;
}
input:-ms-input-placeholder{
	color: #888888;
}
input::placeholder{
	color: #888888;
}
h1{
	font-size: 36px;
	font-weight: bold;
  	line-height: 1.22;
  	color: #111111;
}
.yellow-btn{
	background-color: #ffc93b;
	box-shadow: 4px 4px 0 0 rgba(0, 0, 0, 0.2);
	padding: 0 16px 0 16px;
	margin-top: 19px;
	font-size: 14px;
	font-weight: 600;
	line-height: 2.57;
	letter-spacing: 1.5px;
	color: #000000;
	border-radius: 0;
	transition: all .2s ease-in;
}
.yellow-btn:focus{
	color: #000000;
}
.yellow-btn:hover:not([disabled]){
	background-color: #e6b434;
	color: #000000;
	transition: all .0s ease-out
}
.yellow-btn[disabled]{
	cursor: default;
	color: #000000;
}
.yellow-btn:active,
.black-btn:active{
	-webkit-transform: translate(4px, 4px);
	        transform: translate(4px, 4px);
}
.black-btn{
	background-color: #000000;
	box-shadow: 4px 4px 0 0 rgba(0, 0, 0, 0.4);
	border-radius: 0;
	font-size: 16px;
	font-weight: 600;
	line-height: 2.25;
	letter-spacing: 2px;
	color: #ffffff;
	transition: all .2s ease-in
}
.black-btn:hover{
	background-color: #333333;
	color: #ffffff;
	transition: all .0s ease-out
}
.black-btn:focus{
	color: #ffffff;
}
span.logo.pdf,
span.logo.zip{
	display: inline-block;
	font-size: 11px;
	font-weight: bold;
	line-height: 14px;
	width: 30px;
	text-align: center;
	letter-spacing: 1.2px;
	opacity: 0.9;
	margin-left: 6px;
}
span.logo.pdf{
	background-color: #f62600;
	color: #ffffff;
}
span.logo.zip{
	background-color: #ffc93b;
	color: #000000;
}
span.size{
	font-size: 12px;
	color: #888888;
	margin-left: 4px;
}
.popup{
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 700;
	overflow-y: scroll;
}
.popup .win{
	background-color: #ffffff;
	position: relative;
	box-shadow: 10px 10px 0 0 rgba(0, 0, 0, 0.5);
}
.win button.closeBtn{
    position: absolute;
    top: 0;
    right: 0;
    border: 0;
    background-color: transparent;
}
input[type="checkbox"],
input[type="radio"] {
	display: none;
}
label.check{
	font-size: 0;
	margin-right: 16px;
}
label.check i {
	display: inline-block;
	height: 12px;
	width: 12px;
	position: relative;
	top: 1px;
	font-style: normal;
	color: #ccc;
	cursor: pointer;
	border: solid 2px #ffc93b;
}
label.check span {
	display: inline-block;
	margin-left: 7px;
	color: #000000;
	font-size: 14px;
  	line-height: 1.5;
  	font-weight: normal;
  	cursor: pointer;
}
label.check input[type="checkbox"] + i:before,
label.check input[type="radio"] + i:before{
	position: absolute;
	height: 12px;
	width: 12px;
	top: -4px;
    left: -1px;    
}
label.check input[type="checkbox"]:checked + i,
label.check input[type="radio"]:checked + i{
	position: relative;
	-webkit-animation: icon-beat 0.1s ease;
	        animation: icon-beat 0.1s ease;
}
label.check input[type="checkbox"]:checked + i:before,
label.check input[type="radio"]:checked + i:before{
	content: url("../img/checkbox-checked.svg");
}
.preloader{
	height: 36px;
	width: 36px;
	border-top: solid 4px #000000;
	border-right: solid 4px #000000;
	border-left: solid 4px #000000;
	border-bottom: solid 4px #ffc93b;
	border-radius: 100%;
	display: inline-block;
	position: relative;
	-webkit-animation: spin 2s linear infinite;
	        animation: spin 2s linear infinite;
}
.preloader + p{
	display: inline-block;
	font-size: 16px;
  	line-height: 1.5;
  	color: #000000;
  	margin-left: 14px;
  	vertical-align: top;
    margin-top: 8px;
}
@-webkit-keyframes spin {
    from {-webkit-transform:rotate(0deg);transform:rotate(0deg);}
    to {-webkit-transform:rotate(360deg);transform:rotate(360deg);}
}
@keyframes spin {
    from {-webkit-transform:rotate(0deg);transform:rotate(0deg);}
    to {-webkit-transform:rotate(360deg);transform:rotate(360deg);}
}
.breadcrumbs{
	margin-bottom: 2px;
}
.breadcrumbs a:first-child{
	position: relative;
	padding-left: 15px;
}
.breadcrumbs a:first-child:before{
	content: "";
	position: absolute;
	top: 5px;
	left: 0;
	background-image: url("../img/icon-catalog-burger.svg");
	width: 11px;
	height: 10px;
}
.breadcrumbs a,
.breadcrumbs span{
	font-size: 14px;
  	line-height: 1.5;
}
.breadcrumbs .catalog-home{
	color: #000000;
}
.breadcrumbs span{
	display: inline-block;
	margin: 0 8px;
	color: #000000;
}
.breadcrumbs .model{
	color: rgba(0, 0, 0, 0.5);
}
/*Прилепающий футер*/
html {
    min-height: 100%;
    position: relative;
}
body {
    margin-bottom: 203px;
    /*Скрытие трактора*/
    overflow-x: hidden;
}
footer {
    bottom: 0;
    height: 203px;
    position: absolute;
    width: 100%;
}
@media (min-width: 1200px){
	.container{
		width: 1200px;
	}
	html header:after{
		transition: all 300ms;
	}
}
@media (min-width: 768px){
	.popup{
		position: fixed;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		    -ms-flex-align: center;
		        align-items: center;
		-webkit-box-pack: center;
		    -ms-flex-pack: center;
		        justify-content: center;
	}
}
@media (max-width: 992px) and (min-width: 768px){
	.container {
	    width: 768px;
	}
}
@media (max-width: 1199px){
	.container{
		padding-left: 48px;
		padding-right: 48px;
	}
	.wrapper {
	    margin-bottom: -241px;
	}
	footer, .wrapper:after {
	  height: 241px; 
	}
}
@media (max-width: 767px){
	h1{
		font-size: 24px;
		font-weight: bold;	
	  	line-height: 1.33;
	  	color: #000000;
	}
	main{
		padding-bottom: 135px;
	}
	html.limited,
	html.limited body{
		height: 100%;
		overflow: hidden;
	}
	.container{
		padding-left: 18px;
		padding-right: 18px;
	}
	.popup{
		position: absolute;
		display: block;
	}
	.popup .win{
		width: 100%;
		height: 100%;
	}
	.breadcrumbs{
		display: none;
	}
	body {
	    margin-bottom: 348px;
	}
	footer {
	    height: 348px;
	}
}
header{
	padding-top: 47px;
}
header .row{
	margin-left: 0px;
	margin-right: 0px;
}
.city-pickup-wrapper{
	position: relative;
	display: inline-block;
}
.city-btn{
	position: relative;
	background-color: #fff;
	border: none;
	padding: 0;
	padding-left: 14px;
}
.city-btn:before{
	content: "";
	background-image: url("../img/icon-location.svg");
	position: absolute;
	left: 0;
	top: 3px;
	height: 13px;
	width: 9px;
}
.city-btn span{
	font-size: 14px;
	border-bottom: 1px dashed rgba(0, 0, 0, 0.3);
}
.city-btn:hover span{
	border-color: #000000;
}
.checkout-link{
	position: relative;
	display: inline-block;
	font-size: 14px;
	font-weight: 600;
	line-height: 2.57;
	letter-spacing: 1.5px;
	text-transform: uppercase;
	color: #000000;
	padding: 0 19px 0 33px;
	margin-right: 38px;
	margin-top: 7px;
	vertical-align: top;
}
.checkout-link.shaker {
  -webkit-animation: shake 0.5s cubic-bezier(.36,.07,.19,.97) both;
          animation: shake 0.5s cubic-bezier(.36,.07,.19,.97) both;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-perspective: 1000px;
          perspective: 1000px;
}
@-webkit-keyframes shake {
  10%, 90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0);
  }
}
@keyframes shake {
  10%, 90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0);
  }
}
#fixed-panel .checkout-link{
	padding-right: 10px;
}
.cart-in{
	position: absolute;
	right: 0;
	top: 0;
	background-color: #f62600;
	width: 16px;
	height: 16px;
    -webkit-transform: translate(50%, -50%);
            transform: translate(50%, -50%);
    border-radius: 100%;
}
.btn-main-menu .cart-in{
	right: 7px;
    top: -4px;
    -webkit-transform: none;
            transform: none;
}
#fixed-panel .cart-in{
	top: 7px;
}
.cart-in span{
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-45%, -50%);
	        transform: translate(-45%, -50%);
	font-size: 10px;
	color: #ffffff;
	line-height: 1;
}
.btn-main-menu .cart-in span{
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
}
.header-right .checkout-link{
	border: solid 2px #ffc93b;
}
#dropd-main-menu .checkout-link{
	border: solid 2px #000000;
}
.checkout-link:before{
	content: "";
	background-image: url("../img/icon-cart-black-16.svg");
	position: absolute;
	left: 10px;
	top: 9px;
	height: 16px;
	width: 16px;
}
a.header-tel{
  	font-size: 20px;
  	font-weight: 600;
  	color: #000000;
  	display: inline-block;
  	margin-top: 14px;
}
.callback-link{
	font-size: 14px;
	line-height: 1.5;
	background-color: transparent;
	padding: 0;
	border-top: none;
	border-left: none;
	border-right: none;
	border-radius: 0;
	margin-top: 6px;
}
.header-left{
	float: left;
}
.header-left .dropdown{
	display: inline-block;
}
.header-left .city-btn{
	margin-top: 21px;
	margin-left: 21px;
	/*Для #city-popup*/
	margin-right: 105px;
}
.header-right{
	float: right;
}
.header-right > div{
	display: inline-block;
	text-align: right;
}
.btn-main-menu{
	position: relative;
	background-color: transparent;
	border: none;
	height: 35px;
}
.btn-main-menu .menu-logo{
	position: relative;
	height: 0;
	width: 42px;
}
.btn-main-menu .menu-logo span{
	transition: all 400ms ease 0ms;
	display: block;
	background-color: #000000;
	height: 3px;
	position: absolute;
	left: 0;
	top: 0;
}
.btn-main-menu .menu-logo:not(.open) span:first-child{
	width: 33px;
	top: -8px;
}
.btn-main-menu .menu-logo:not(.open) span:nth-child(2){
	width: 22px;
}
.btn-main-menu .menu-logo:not(.open) span:last-child{
	width: 27px;
	top: 8px;
}
.btn-main-menu .menu-logo.open span:first-child{
	-webkit-transform: rotate(45deg);
	        transform: rotate(45deg);
	width: 42px;
}
.btn-main-menu .menu-logo.open span:last-child{
	-webkit-transform: rotate(-45deg);
	        transform: rotate(-45deg);
	width: 42px;
}
.row.main-menu-desktop li{
	display: inline-block;
}
.row.main-menu-desktop li a,
.row.main-menu-desktop li > span{
	padding: 0 9px 0;
	line-height: 34px;
	display: inline-block;
}
.row.main-menu-desktop li:first-child a{
	margin-left: -9px;
}
.main-menu-desktop li a span,
.main-menu-desktop li > span{
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 1.5px;
	line-height: 1.8;
	color: #000000;
}
.main-menu-desktop li > span{
	display: inline-block;
	cursor: default;
}
.main-menu-desktop li a span{	
	border-bottom: solid 2px rgba(0, 0, 0, 0.3);
}
.row.main-menu-desktop li a span,
.row.main-menu-desktop li > span{
	font-size: 14px;
}
.row.main-menu-desktop li + li{
	margin-left: 4px;
}
.row.main-menu-desktop li > a:focus,
.row.main-menu-desktop li > a:hover,
.row.main-menu-desktop li.active > span{
	background-color: #ffc93b;
}
.row.main-menu-desktop li > a:focus span,
.row.main-menu-desktop li > a:hover span{
	border-bottom: none;
}
#dropd-main-menu{
	position: absolute;
	background-image: linear-gradient(to top, #ffc838, #ffd35d);
	background-color: #ffc838;
	width: 100%;
	top: 72px;
	z-index: 200;
	visibility: hidden;
	-webkit-transform: translateY(-100%);
	        transform: translateY(-100%);
	transition: all 200ms cubic-bezier(0, 0.9, 0.4, 1);

}
#dropd-main-menu.cubic{
	transition-timing-function: cubic-bezier(0.26, 0.92, 0.34, 1);
}
#dropd-main-menu.open{
	visibility: visible;
	-webkit-transform: translateY(0);
	        transform: translateY(0);
	box-shadow: 0 25px 36px 0 rgba(0, 0, 0, 0.57);
}
#dropd-main-menu .container{
	margin: 30px auto 0;
}
#dropd-main-menu .city-list{
	margin-top: 30px;
	margin-bottom: 19px;
	padding-left: 15px;
}
.main-menu-desktop li a span,
.main-menu-desktop li > span{
	font-size: 16px;
}
.city-list li a,
.city-list li a:focus{
	font-size: 14px;
	color: #000000;
	text-decoration: underline;
	-webkit-text-decoration-color: rgba(0, 0, 0, 0.7);
	        text-decoration-color: rgba(0, 0, 0, 0.7);
}
.city-list li.active a,
.city-list li a:hover{
	color: rgba(0, 0, 0, 0.5);
	text-decoration: none;
}
#city-popup{
	position: absolute;
	left: 19px;
	top: 7px;
	background-color: #ffc93b;
	padding: 14px 27px 32px 16px;
	z-index: 100;
	box-shadow: 4px 4px 0 0 rgba(0, 0, 0, 0.2);
}
#city-popup li a{
	font-size: 14px;
	color: #000000;
	border-bottom: solid 1px rgba(0, 0, 0, 0.3);
}
#city-popup li a:hover{
	border-color: #000000;
}
#city-popup li.active a{
	border-bottom-style: dashed;
}
#city-popup li + li{
	margin-top: 9px;
}
#fixed-panel{
	background-color: #ffc93b;
	box-shadow: 0 12px 14px 0 rgba(0, 0, 0, 0.13);
	height: 0;
	visibility: hidden;
}
#fixed-panel.affix-top *{
	height: 0;
	margin: 0;
	padding: 0;
}
#fixed-panel.affix{
	visibility:	visible;
	height: auto;
	padding: 7px 0 5px;
	top: 0;
	width: 100%;
	z-index: 300;
}
#fixed-panel .checkout-link{
	margin-top: 0;
	border: none;
}
#fixed-panel .breadcrumbs{
	float: left;
	margin-bottom: 0;
	margin-top: 7px;
}
#fixed-panel .header-tel{
	margin-top: 5px;
	font-size: 16px;
  	line-height: 1.5;
}
@media (min-width: 1200px){
	.btn-main-menu{
		display: none;
	}
}
@media (min-width: 768px){
	.main-menu-desktop li + li{
		margin-top: 12px;
	}
	.city-list li{
		display: inline-block;
	}
	.city-list li + li{
		margin-left: 15px;
	}
}
@media (max-width: 1199px){
	.header-left .city-btn,
	.header-left + .header-right .checkout-link,
	.header-right .callback-link,
	.header-left + .header-right .header-tel,
	.row.main-menu-desktop{
		display: none;
	}
	.header-left img{
		height: 40px;
	}
	header{
		padding-top: 13px;
		padding-bottom: 19px;
		background-image: linear-gradient(to top, #ffc838, #ffd35d);
		background-color: #ffc838;
		z-index: 250;
		position: relative;
	}
	#fixed-panel .breadcrumbs{
		display: none;
	}
	#fixed-panel .header-right{
		float: none;
	}
	#fixed-panel .header-tel{
		float: right;
	}
}
@media (max-width: 767px){
	header{
		padding: 14px 0;
	}
	.btn-main-menu .menu-logo-close{
	    margin-top: 6px;
	}
	.btn-main-menu .menu-logo-open{
		width: 36px;
    	height: 26px;
	}
	.btn-main-menu .menu-logo-open span{
		width: 35px;
	}
	.btn-main-menu{
		height: 28px;
	}
	.btn-main-menu .menu-logo.open span:first-child,
	.btn-main-menu .menu-logo.open span:last-child{
		width: 35px;
	}
	.header-left img{
		height: 32px;
	}
	#dropd-main-menu{
		top: 60px;
		height: 100%;
		overflow-y: scroll;
	}
	#dropd-main-menu .container{
		margin-top: 12px;
	}
	.main-menu-desktop li a span{
		font-size: 16px;
		line-height: 2.25;
		letter-spacing: 2px;
	}
	.checkout-link{
		margin-top: 32px;
	}
	a.header-tel{
		margin-top: 19px;
	}
	#dropd-main-menu .city-list{
		margin-top: 24px;
	}
	.city-list li + li{
		margin-top: 9px;
	}
	#fixed-panel .header-tel{
		display: none;
	}
}

footer .container{
	padding-bottom: 55px;
}
.grey-line{
	border-top: 2px solid #111111;
	padding-top: 40px;
	margin-left: 15px;
	margin-right: 15px;
}
footer .container *{
	font-size: 12px;
  	line-height: 2;
  	color: #000000;
}
ul.menu-bottom li{
	display: inline-block;
	margin-right: 12px;
}
ul.menu-bottom li a{
	border-bottom: solid 1px rgba(0, 0, 0, 0.3);
}
ul.menu-bottom li a:hover{
	border-color: #000000;
}
.footer-share-btns{
	display: inline-block;
	margin-top: 23px;
}
.footer-share-btns ul{
	display: inline-block;
}
.footer-share-btns ul li{
	display: inline-block;
	margin-left: 10px;
}
@media (min-width: 1200px){
	footer .container .footer-contacts{
		float: left;
		width: 33%;
	}
	footer .container .design-author{
		text-align: right;
	}
}
@media (max-width: 1199px){
	footer .container .design-author{
		margin-top: 24px;
	}
}
@media (max-width: 767px){
	footer .container .col-lg-5{
		margin-bottom: 25px;
	}
	.grey-line{
		margin-left: -3px;
		margin-right: -3px;
	}
}

main.main{
	padding-top: 36px;
}
.main-search{
	padding: 28px 70px 113px 40px;
	background-image: url("../img/blocks-main.png"), linear-gradient(to bottom, #ffd35d, #ffc838);
	background-position: bottom right;
	background-repeat: no-repeat;
}
.main-search h2{
	font-size: 32px;
	font-weight: bold;
  	line-height: 1.25;
  	color: #111111;
  	margin-top: 0;
  	margin-bottom: 18px;
}
.main-search span{
	font-size: 12px;
	line-height: 1.25;
	display: inline-block;
}
.search-sample{
	cursor: pointer;
	border-bottom: dashed 1px rgba(0, 0, 0, 0.5);
}
.main-search p{
	font-size: 20px;
	line-height: 1.5;
	margin-top: 20px;
	margin-bottom: 0;
}
#main-search-input{
	width: 544px;
	height: 48px;
	background-color: #ffffff;
	border: solid 2px #000000;
	border-radius: 0;
	box-shadow: 3px 4px 0 0 rgba(0, 0, 0, 0.4);
	padding: 8px 10px 10px 36px;
	margin-bottom: 5px;
  	font-size: 18px;
  	line-height: 1.67;
}
.main-input-wrapper{
	position: relative;
}
.main-search-btn{
	width: 105px;
	height: 48px;	
	margin-left: -5px;
	margin-top: -4px;
}
.main-search-btn:hover,
.main-search-btn:focus{
	color: #ffffff;
}
main.main .black-btn{
	box-shadow: 4px 4px 0 0 rgba(0, 0, 0, 0.2);
}
.main-sales{
	background-image: url("../img/blocks__detail.png"), linear-gradient(to bottom, #ffd35d, #ffc838);
	background-color: #ffc838;
	background-repeat: no-repeat;
	background-position: right top;
	padding: 16px 24px 22px;
}
.main-sales h2{
	font-size: 24px;
  	font-weight: bold;
  	line-height: 1.33;
  	color: #000000;
  	margin: 0;
}
.main-sales p{
	font-size: 16px;
  	line-height: 1.5;
  	margin-bottom: 0;
}
#get-sale{
	position: relative;
	font-size: 14px;
	font-weight: 600;
	line-height: 2.57;
	letter-spacing: 1.5px;
	padding: 0 42px 0 16px;
	margin-top: 39px;
}
#get-sale:after{
	background-image: url("../img/icon-arrow-left-white.svg");
	content: "";
	position: absolute;
	height: 16px;
	width: 16px;
	top: 10px;
	right: 21px;
}
.sales-subscript{
	border: solid 4px #ffc93b;
	padding: 16px 24px 22px;
	margin-top: 30px;
}
.sales-subscript h2{
	font-size: 24px;
	font-weight: bold;
	line-height: 1.33;
	color: #000000;
	margin: 0;
}
.sales-subscript p{
	font-size: 14px;
  	line-height: 1.5;
  	margin-bottom: 0;
}
.tractor-models{
	margin-top: 30px;
	margin-right: 0;
	margin-left: 0;
}
.tractor-models .col-md-2 a{
	display: inline-block;
	border: solid 4px #ffc93b;
	height: 96px;
	width: 100%;
	padding-top: 16px;
	padding-left: 13px;
	background-position: center right;
	background-repeat: no-repeat;
	margin-bottom: 30px;
}
.tractor-models a.k-700a{
	background-image: url("../img/blocks-k-700-yellow.png");
}
.tractor-models a.k-701{
	background-image: url("../img/blocks-k-701-yellow.png");
}
.tractor-models a.k-702{
	background-image: url("../img/blocks-k-702-yellow.png");
}
.tractor-models a.k-744{
	background-image: url("../img/blocks-k-744-yellow.png");
}
.tractor-models a.k-703{
	background-image: url("../img/blocks-k-703-yellow.png");
}
.tractor-models a span{
	display: inline-block;
	line-height: 1;
	font-size: 32px;
  	font-weight: bold;
  	color: #000000;
  	border-bottom: solid 1px rgba(0, 0, 0, 0.3);
}
.tractor-models a:hover span{
	border-color: #000000;
}
.tractor-models h2{
	display: none;
	font-size: 14px;
	font-weight: bold;
	line-height: 1.5;
	color: #000000;
}
.second-text-block .col-lg-9 h2{
	font-size: 24px;
	font-weight: bold;
	line-height: 1.33;
	color: #000000;
	margin-top: 0;
	margin-bottom: 23px;
}
.second-text-block .col-lg-9 h2:nth-child(4){
	margin-top: 53px;
}
.second-text-block .col-lg-9 p{
	font-size: 16px;
  	line-height: 1.5;
  	opacity: 0.9;
}
.second-text-block .col-lg-3 h2{
	font-size: 16px;
	font-weight: bold;
	line-height: 1.5;
	color: #000000;
	margin-bottom: 2px;
}
.second-text-block .col-lg-3 a{
	font-size: 14px;
  	line-height: 1.5;
  	opacity: 0.9;
  	color: #000000;
  	border-bottom: solid 1px rgba(0, 0, 0, 0.3);
}
.second-text-block .col-lg-3 a:hover{
	border-color: #000000;
}
.second-text-block .col-lg-3 div.col-lg-12{
	margin-bottom: 9px;
}
.sales-subscript-form-wrapper{
	background-image: url("../img/pattern.svg");
	padding: 10px;
	margin-top: 103px;
}
.sales-subscript-form{
	padding-top: 43px;
	padding-bottom: 66px;
	background-color: #ffffff;
	margin-left: 0;
	margin-right: 0;
}
.sales-subscript-form .col-lg-8 h2{
	font-size: 24px;
  	font-weight: bold;
  	line-height: 1.33;
  	color: #000000;
  	margin-top: 0;
  	margin-bottom: 5px;
}
.sales-subscript-form .col-lg-8 p{
	font-size: 16px;
  	line-height: 1.5;
}
.sales-subscript-form .col-lg-8 h3{
	font-size: 16px;
	font-weight: bold;
	line-height: 1.5;
	margin-top: 23px;
	margin-bottom: 0;
	color: #111111;
}
form span.fieldname{
	font-size: 14px;
	font-weight: bold;
	line-height: 1.5;
	display: block;
}
form span.error{
	font-size: 12px;
	line-height: 1.75;
	color: #f52700;
	margin-top: 2px;
	display: block;
}
form input,
form textarea{
	border: solid 2px #ffc93b;
	border-radius: 0;
	font-size: 16px;
	line-height: 1.5;
	padding: 6px 12px;
}
form input:focus{
	box-shadow: 4px 4px 0 0 rgba(0, 0, 0, 0.2);
}
form input.error{
	border-color: #f62600;
}
form > span + span{
	margin-top: 14px;
}
form input,
form .yellow-btn{
	width: 240px;
}
form .yellow-btn{
	margin-top: 24px;
	margin-bottom: 12px;
}
form .yellow-btn + span{
	display: block;
	font-size: 12px;
	line-height: 1.25;
	color: #888888;
}
#sales-subscript-form h2.success{
	font-size: 24px;
	font-weight: 600;
	line-height: 1.33;
	color: #00bc66;
	position: relative;
	display: inline-block;
	margin-top: 0;
	margin-bottom: 11px;
}
h2.success:after{
	content: "";
	position: absolute;
	top: 0;
	right: -36px;
	width: 30px;
	height: 28px;
	background-image: url("../img/galka-success.svg");
}
.zapros h2.success:after{
	top: 5px;
	right: -36px;
}
#sales-subscript-form p{
	font-size: 16px;
  	line-height: 1.5;
  	color: #000000;
}
#recall textarea{
	resize: none;
	height: 84px;
	width: 280px;
}
#recall .not-requered{
	font-size: 12px;
	line-height: 1.25;
	text-align: left;
	color: #888888;
	display: block;
}
#recall .win.zapros h2{
	margin-bottom: 22px;
}
#send-recall{
	text-transform: uppercase;
}
@media (min-width: 1200px){
	.second-text-block{
		margin-top: 60px;
	}
	.second-text-block .col-lg-3{
		margin-top: 35px;
	}
	.sales-subscript-form .col-lg-8{
		padding: 0 50px;
	}
}
@media (min-width: 768px){
	.main-input-wrapper:before{
		content: "";
		position: absolute;
		top: 14px;
		left: 12px;
		height: 16px;
		width: 16px;
		background-image: url("../img/icon-search-light-16.svg");
	}
	#recall .not-requered{
		margin-left: 125px;
	}
}
@media (max-width: 1199px) and (min-width: 768px){
	main.main{
		overflow-x: hidden;
	}
	main.main > .container:first-child:after{
		position: absolute;
		z-index: -1;
		content: "";
		opacity: 0.5;
		background-image: url(../img/engine__yellow.png);
		width: 776px;
		height: 591px;
		top: 0;
		right: -52%;
	}
	.sales-subscript-form-wrapper{
		margin-left: -48px;
    	margin-right: -48px;
	}
	.sales-subscript-form .col-lg-8{
		padding-left: 38px;
		padding-right: 38px;
	}
}
@media (max-width: 1199px){
	main.main .container  > .row > .col-lg-8{
		margin-bottom: 50px;
	}
	.main-search{
		background-image: none;
		padding: 0;
	}
	#main-search-input{
		box-shadow: 3px 4px 0 0 #888888;
		width: 84%;
	}
	.main-search h2{
		font-size: 36px;
		line-height: 1.22;
		margin-bottom: 34px;
	}
	.main-search p{
		margin-top: 13px;
	}
	.sales-subscript{
		margin-top: 0;
	}
	.main-search-btn{
		box-shadow: 4px 4px 0 0 #888888 !important;
	}
	.tractor-models a span{
		font-size: 27px;
	}
	.second-text-block{
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		    -ms-flex-direction: column;
		        flex-direction: column;
	}
	.second-text-block .col-lg-3{
		-webkit-box-ordinal-group: 2;
		    -ms-flex-order: 1;
		        order: 1;
	}
	.second-text-block .col-lg-9{
		-webkit-box-ordinal-group: 3;
		    -ms-flex-order: 2;
		        order: 2;
		margin-top: 65px;
	}
	.sales-subscript-form .col-lg-4{
		padding: 54px 50px 43px;
	}
	.main-sales,
	.sales-subscript{
		min-height: 195px;
	}
}
@media (max-width: 767px){
	main.main{
		padding-top: 16px;
	}
	.main .container .row:first-child{
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		    -ms-flex-direction: column;
		        flex-direction: column;
	}
	main.main .col-lg-8{
		padding-left: 15px;
		margin-bottom: 21px;
		-webkit-box-ordinal-group: 2;
		    -ms-flex-order: 1;
		        order: 1;
	}
	main.main .col-lg-4{
		-webkit-box-ordinal-group: 4;
		    -ms-flex-order: 3;
		        order: 3;
	}
	main.main .col-lg-4 .col-lg-12{
		padding-left: 15px !important;
		padding-right: 15px !important;
	}
	.main-search h2{
		font-size: 20px;
		line-height: 1.2;
		margin-bottom: 8px;
	}
	#main-search-input{
		padding-left: 16px;
		width: 80%;
	}
	.main-search-btn{
		font-size: 0;
		position: relative;
		width: 48px;
	}
	.main-search-btn:after{
		position: absolute;
		content: "";
		background-image: url("../img/icon-search-white-16.svg");
		height: 16px;
		width: 16px;
		right: 16px;
		top: 14px;
	}
	.main-sales{
		background-image: none;
		background-color: transparent;
		padding: 43px 0 22px;
	}
	#get-sale {
		margin-top: 18px;
	}
	.sales-subscript{
		border: none;
		padding: 16px 0 22px;
	}
	.tractor-models{
		margin: 0;
		-webkit-box-ordinal-group: 3;
		    -ms-flex-order: 2;
		        order: 2;
	}
	.tractor-models h2{
		margin-top: 0;
		padding-left: 15px;
		display: block;
	}
	.tractor-models .col-md-2 a{
		border: none;
		background-image: none !important;
		height: auto;
		width: auto;
		padding: 0;
		margin-bottom: 12px;;
	}
	.tractor-models a span{
		font-size: 20px;
		line-height: 1.2;
	}
	.second-text-block .col-lg-3,
	.second-text-block .col-lg-9{
		padding-left: 15px;
	}
	.second-text-block .col-lg-9{
		margin-top: 55px;
	}
	.second-text-block .col-lg-9 h2{
		font-size: 20px;
		margin-bottom: 14px;
	}
	.sales-subscript-form-wrapper{
		padding-left: 0;
		padding-right: 0;
		margin-left: -18px;
		margin-right: -18px;
		margin-top: 78px;
	}
	.sales-subscript-form {
		padding-top: 30px;
		background-color: #fffaea;
	}
	.sales-subscript-form .col-lg-8 h2{
		font-size: 20px;
	}
	.sales-subscript-form .col-lg-8 p{
		font-size: 14px;
	}
	.sales-subscript-form .col-lg-8 h3{
		margin-top: 12px;
	}
	.sales-subscript-form .col-lg-4{
		padding: 0 15px;
	}
	#recall .win.zapros{
		min-height: 530px;
	}
	#recall textarea{
		width: 100%;
	}
}
main.about{
	padding-bottom: 90px;
}
main.about h1{
	margin-top: 40px;
	margin-bottom: 5px;
}
.tabs-headers{
	width: 420px;
}
.tabs-headers li{
	display: inline-block;
}
.tabs-headers li + li{
	margin-left: 16px;
}
.tabs-headers li a{
	color: #000000;
	font-size: 16px;
  	line-height: 2.13;
  	border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
.tabs-headers li a:hover{
	border-color: #000000;
}
.tabs-headers li.active a{
	color: rgba(0, 0, 0, 0.3);
	border-color: #ffc93b;
	border-width: 3px;
}
.tab-content{
	padding-top: 43px;
}
main.about .tab-content #about p,
main.about .tab-content #vacancy p{
	font-size: 16px;
	line-height: 1.5;
	margin-bottom: 0;
}
main.about #about .file-link .all-logos{
	margin-bottom: 20px;
}
main.about .tab-content #about p + p{
	margin-top: 24px;
}
main.about .tab-content .file-link .link-name{
	font-size: 14px;
  	line-height: 1.5;
  	color: #000000;
  	border-bottom: solid 1px rgba(0, 0, 0, 0.3);
}
main.about .tab-content .file-link .link-name:hover{
	border-color: #000000;
}
main.about .tab-content .file-link .logo-big{
	margin-bottom: 20px;
}
main.about .tab-content .file-link p{
	font-size: 12px !important;
  	line-height: 1.5;
}
main.about .tab-content .logo.zip,
main.about .tab-content .logo.pdf{
	margin: 5px 0;
}
main.about .tab-content ul{
	margin-bottom: 26px;
}
main.about .tab-content ul li{
	font-size: 16px;
	line-height: 1.31;
	position: relative;
	padding-left: 36px;
}
main.about .tab-content ul li:before{
	position: absolute;
	content: "";
	width: 14px;
	height: 1px;
	background-color: #111111;
	top: 10px;
	left: 12px;
}
main.about .tab-content ul li + li{
	margin-top: 11px;
}
main.about #vacancy ul li + li{
	margin-top: 8px;
}
main.about .tab-content #about h2{
	font-size: 24px;
  	font-weight: bold;
  	line-height: 1.33;
  	margin-top: 53px;
  	margin-bottom: 23px;
  	color: #000000;
}
main.about .tab-content #documents .file-link{
	width: 270px;
	display: inline-block;
	vertical-align: top;
}
main.about .tab-content #documents .col-lg-9 .file-link{
	margin-bottom: 68px;
	padding-right: 30px;
}
main.about .tab-content .file-link .logo-big{
	height: 180px;
	position: relative;
}
main.about .tab-content .file-link .logo-big a{
	position: absolute;
	bottom: 0;
	left: 0;
}
main.about .tab-content #documents .file-link .logo-mini{
	padding-left: 40px;
	position: relative;
}
main.about .tab-content #documents .file-link .logo-mini:before{
	content: "";
	position: absolute;
	background-image: url("../img/all-documents.svg");
	height: 32px;
	width: 32px;
	top: 4px;
    left: 0;
}
main.about .tab-content #documents .file-link img:not(.logos),
main.about .tab-content #requisites .file-link img:not(.logos){
	width: 128px;
	height: 100%;
	border: solid 1px #b2b2b2;
	box-shadow: 5px 5px 0 0 rgba(0, 0, 0, 0.3);
}
main.about .tab-content h2{
	font-size: 24px;
  	font-weight: bold;
  	line-height: 1.33;
  	color: #000000;
  	margin-top: 0;
  	margin-bottom: 23px;
}
main.about .tab-content p + h2{
	margin-top: 41px
}
main.about .tab-content #requisites p{
	margin-bottom: 12px;
	position: relative;
}
main.about .tab-content #requisites .col-lg-9{
	position: relative;
}
main.about .tab-content #requisites .col-lg-9 span:not(.logo):not(.size){
	font-size: 16px;
	line-height: 1.5;
	color: #000000;
}
main.about .tab-content #requisites span.req-name{
  	font-weight: bold;
  	width: 150px;
}
main.about .tab-content #requisites span.buffer{
	padding-bottom: 10px;
    display: block;
}
main.about .tab-content #requisites .col-lg-9 .requisites-md .link-name:nth-child(2){
	display: none;
}
main.about .tab-content #vacancy h2{
	margin-bottom: 14px;
}
main.about .tab-content #vacancy .cond{
	font-weight: bold;
	margin-bottom: 0;
}
main.about .tab-content .vacancy-contacts p:first-child{
	font-weight: bold;
}
main.about .tab-content .vacancy-contacts p:nth-child(2){
	margin-bottom: 25px;
}
main.about .tab-content .vacancy-contacts a,
main.about .tab-content .vacancy-contacts a:hover{
	color: #000000;
	line-height: 1.5;
}
@media (min-width: 1200px){
	main.about .tab-content .vacancy-contacts{
		padding-top: 10px;
	}
	main.about .tab-content .vacancy-contacts p{
		font-size: 14px !important;
	}
	main.about .tab-content #about .col-lg-9{
		padding-right: 100px;
	}
}
@media (min-width: 768px){
	main.about .tab-content #about,
	main.about .tab-content #requisites,
	main.about .tab-content #vacancy{
		margin-bottom: 68px;
	}
	main.about .tab-content #requisites p{
		margin-left: 174px;
	}
	main.about .tab-content #requisites span.req-name{
		position: absolute;
  		left: -174px;
	}
}
@media (max-width: 1199px){
	main.about{
		padding-bottom: 50px;
	}
	main.about h1{
		margin-top: 35px;
	}
	main.about .tab-content #documents .col-lg-9 .file-link{
		width: 49%;
	}
	main.about .tab-content #documents .file-link .logo-mini:before{
		right: 85px;
		left: auto;
	}
	main.about .tab-content #documents .col-lg-3 p,
	main.about .tab-content #requisites .col-lg-3{
		display: none;
	}
	main.about .tab-content #requisites .col-lg-9 .requisites-md{
		position: absolute;
		top: 5px;
		right: 15px;
	}
	main.about .tab-content #requisites .col-lg-9 .requisites-md .logo{
		margin-left: 8px;
	}
	main.about .tab-content #vacancy.active{
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: reverse;
		    -ms-flex-direction: column-reverse;
		        flex-direction: column-reverse;
	}
	main.about .tab-content .vacancy-contacts{
		margin-bottom: 34px;
	}
}
@media (max-width: 1199px) and (min-width: 768px){
	main.about .tab-content #documents .col-lg-3 .file-link{
		text-align: right;
		float: right;
		padding-right: 0;
	}
	main.about .tab-content #requisites .requisites-md > div{
		display: inline-block;
	}
	main.about .tab-content .vacancy-contacts p:nth-child(2),
	main.about .tab-content .vacancy-contacts p:first-child{
		margin-bottom: 0;
	}
}
@media (max-width: 767px){
	main.about{
		padding-bottom: 124px;
	}
	.tab-content{
		padding-top: 19px;
	}
	.scrollyeah{
		margin-left: -18px;
    	margin-right: -18px;
	}
	.tabs-headers{
		padding-left: 18px;
	}
	main.about .tab-content #about .logo-link{
		margin-top: 72px;
	}
	main.about #about .file-link .all-logos{
		margin-top: 62px;
	}
	main.about .tab-content #documents .col-lg-9 .file-link{
		width: auto;
		margin-bottom: 20px;
	}
	main.about .tab-content #documents .file-link .logo-big,
	main.about .tab-content .file-link p{
		display: none;
	}
	main.about .tab-content #documents .file-link .logo-mini{
		padding-left: 0;
	}
	main.about .tab-content #documents .file-link .logo-mini:before{
		display: none;
	}
	main.about .tab-content #requisites{
		padding-top: 60px;
	}
	main.about .tab-content #requisites .col-lg-9 .requisites-md{
		right: auto;
		left: 18px;
		top: -65px;
	}
	main.about .tab-content #requisites .col-lg-9 .requisites-md .logo{
		margin-left: 0;
	}
	main.about .tab-content #requisites .col-lg-9 .requisites-md .link-name:first-child{
		display: none;
	}
	main.about .tab-content #requisites .col-lg-9 .requisites-md .link-name:nth-child(2){
		display: inline-block;
	}
	main.about .tab-content #requisites span.req-name{
		display: block;
	}
	main.about .tab-content .vacancy-contacts p:first-child{
		display: none;
	}
}
main.handbook h1{
	margin-top: 40px;
	margin-bottom: 22px;
}
main.handbook p{
	font-size: 16px;
	line-height: 1.5;
}
ol{
	padding-left: 20px;
}
ol li,
ol li a{
	font-size: 16px;
	line-height: 1.5;
	color: #000000;
}
ol li + li{
	margin-top: 8px;
}
@media (max-width: 1999px) and (min-width: 768px){
	main.handbook{
		padding-bottom: 222px;
	}
}
@media (max-width: 767px){
	main.handbook h1{
		margin-top: 35px;
		margin-bottom: 11px;
	}
	ol li + li{
		margin-top: 9.5px;
	}
	main.handbook ol{
		padding-left: 25px;
	}
}
main.contacts h1{
	margin-top: 40px;
	margin-bottom: 20px;
}
main.contacts .col-sm-6 > div span,
main.contacts .col-sm-6 > div a{
	font-size: 16px;
	line-height: 1.5;
	color: #000000;
}
main.contacts .col-sm-6 > div span{
	display: block;
}
main.contacts .col-sm-6 > div span:first-child{
	font-weight: bold;
}
main.contacts .col-sm-6 > div + div{
	margin-top: 24px
}
main.contacts .yamap{
	padding-top: 53px;
	padding-bottom: 18px;
}
main.contacts h2{
	font-size: 24px;
  	font-weight: bold;
  	line-height: 1.33;
  	color: #000000;
}
main.contacts .yamap h2{
	display: inline-block;
  	margin: 0;
  	margin-right: 14px;
}
main.contacts .open-map-in-yandex{
	font-size: 14px;
  	line-height: 1.5;
  	color: #000000;
  	position: relative;
}
main.contacts .open-map-in-yandex:after{
	background-image: url("../img/icon-target.svg");
	content: "";
	position: absolute;
	top: 4px;
    right: -14px;
	width: 10px;
	height: 10px;
}
main.contacts .map-wrapper + .open-map-in-yandex{
	margin-top: 5px;
}
main.contacts .yamap .print-yandex-map{
	font-size: 14px;
  	line-height: 1.5;
  	color: #000000;
  	position: relative;
  	margin-top: 10px;
    display: inline-block;
}
main.contacts .yamap .print-yandex-map:hover{
	color: #000000;
}
main.contacts .yamap .print-yandex-map:before{
	background-image: url("../img/icon-print-16.svg");
	content: "";
	position: absolute;
	top: 4px;
    left: -24px;
	width: 16px;
	height: 16px;
}
main.contacts .yamap .zoom-control{
	display: inline-block;
	margin-left: 45px;
	cursor: pointer;
}
main.contacts .yamap .zoom-control span{
	font-size: 14px;
  	line-height: 1.5;
  	color: #000000;
}
main.contacts .yamap .zoom-control > div{
	height: 1px;
	width: 50px;
	background-color: #b4c1c8;
	position: relative;
	display: inline-block;
    margin: 0 1px 4px;
}
main.contacts .yamap .zoom-control > div:before{
	width: 16px;
  	height: 16px;
  	background-color: #000000;
  	border-radius: 100%;
  	content: "";
  	position: absolute;
  	top: -8px;
  	left: 0;
}
main.contacts .yamap .zoom-control.zoom-out > div:before{
	left: auto;
	right: 0;
}
main.contacts .yamap .left{
	float: left;
}
main.contacts .yamap .right{
	float: right;
}
main.contacts #map{
	width: 100%;
	height: 406px;
	border: solid 1px #000000;
	box-shadow: 4px 4px 0 0 rgba(0, 0, 0, 0.3);
}
main.contacts .map-wrapper{
	margin-right: 0;
	margin-left: 0;
}
.delivery-info{
	padding-top: 76px;
}
.delivery-info h2{
	margin-bottom: 25px;
}
.delivery-info .scrollyeah{
	margin-bottom: 24px;
}
.delivery-info ul{
	font-size: 0;
	width: 380px;
}
.delivery-info ul li{
	display: inline-block;
	font-size: 13px;
  	line-height: 24px;
  	height: 24px;
  	vertical-align: top;
  	padding: 0 10px;
  	border: solid 1px #ffc93b;
  	margin: 0;
  	cursor: pointer;
  	color: #000000;
  	box-sizing: border-box;
}
.delivery-info ul li:hover{
	background-color: #fff1cc;
}
.delivery-info ul li.active{
	background-color: #ffc93b;
	border: none;
}
.delivery-info ul li + li{
	border-left: none;
}
.delivery-info thead{
	border-bottom: 1px solid #b4c1c8;
}
.delivery-info thead tr{
	font-size: 14px;
  	font-weight: bold;
  	line-height: 1.5;
}
.delivery-info thead tr td{
	padding-bottom: 3px;
}
.delivery-info tbody tr td{
	font-size: 14px;
	line-height: 1.5;
}
.delivery-info tbody tr td:first-child{
	width: 30px;
  	font-weight: bold;  	
}
.delivery-info tr td:nth-child(2){
	padding-right: 34px;
}
.delivery-info tr td:nth-child(4){
	padding: 0 42px;
}
.delivery-info tbody tr td{
	padding-top: 3px;
}
.delivery-info tr.first-letter td{
	padding-top: 10px;
}
.delivery-info .delivery-xs{
	display: none;
}
@media (min-width: 768px){
	main.contacts .contacts-second{
		padding-top: 26px;
	}
	main.contacts .map-wrapper + .open-map-in-yandex{
		display: none;
	}
}
@media (max-width: 1199px){
	main.contacts .delivery-info{
		padding-top: 52px;
	}
	main.contacts .yamap .zoom-control{
		display: none;
	}
}
@media (max-width: 767px){
	main.contacts h1{
		margin-top: 35px;
		margin-bottom: 11px;
	}
	main.contacts .yamap{
		padding-bottom: 10px;
	}
	main.contacts #map{
		box-shadow: none;
	}
	main.contacts .map-wrapper{
		margin-right: -19px;
    	margin-left: -19px;
	}
	main.contacts .map-wrapper + .open-map-in-yandex{
		display: inline-block;
	}
	.delivery-info{
		padding-top: 63px;
	}
	.delivery-info ul{
		padding-left: 15px;
		padding-right: 15px;
	}
	.delivery-info h2{
		line-height: 1.2;
		font-size: 20px;
		margin-top: 0;
	}
	.delivery-info table{
		display: none;
	}
	.delivery-info .delivery-xs{
		display: block;
	}
	.delivery-xs > div{
		padding-bottom: 4px;
		border-bottom: 1px solid #e7f0f4;
	}
	.delivery-xs > div + div{
		margin-top: 19px;
	}
	.delivery-xs p{
		position: relative;
		padding-left: 135px;
		margin-bottom: 3px;
	}
	.delivery-xs span{
		font-size: 14px;
		line-height: 1.5;
		color: #000000;
	}
	.delivery-xs p span:first-child{
		position: absolute;
		left: 0;
		font-weight: bold;
	}
}
main.delivery h1{
	margin-top: 40px;
	margin-bottom: 22px;
}
main.delivery h2,
main.delivery p{
	color: #000000;
}
main.delivery .container > p{
	font-size: 16px;
	line-height: 1.5;
}
main.delivery .container > p + p{
	margin-top: 25px;
}
main.delivery p.useful-links{
	font-weight: bold;
	margin-bottom: 3px;
}
main.delivery ul.useful-links li{
	margin-bottom: 9px;
}
main.delivery ul.useful-links li a{
  	line-height: 1.5;
  	color: #000000;
}
main.delivery .tk-info{
	margin-top: 62px;
}
main.delivery .one-tk-info:before{
	display: table;
    content: " ";
}
main.delivery .one-tk-info:after{
	clear: both;
	display: table;
    content: " ";
}
main.delivery .one-tk-info + .one-tk-info{
	margin-top: 64px;
}
main.delivery .tk-info h2{
	font-size: 16px;
  	font-weight: bold;
  	line-height: 1.31;
  	margin-top: 0;
  	margin-bottom: 5px;
}
main.delivery .tk-info img{
	max-width: 180px;
	margin-top: 9px;
}
main.delivery .tk-info p{
	line-height: 1.5;
	margin-bottom: 8px;
}
main.delivery .one-tk-desc + div p{
	margin-bottom: 0;
}
main.delivery .tk-info p.name{
	font-size: 12px;
  	font-weight: bold;
  	margin-bottom: 0;
}
main.delivery .tk-info p + p.name{
	margin-top: 8px;
}
main.delivery .tk-info a{
	line-height: 1.5;
	color: #000000;
}
main.delivery .tk-info a:hover{
	color: #000000;
}
main.delivery .tk-links div{
	margin-bottom: 3px;
}
main.delivery .delivery-info h2{
	font-size: 24px;
  	font-weight: bold;
  	line-height: 1.33;
  	color: #000000;
}
@media (min-width: 1200px){
	main.delivery .one-tk-desc{
		padding-right: 30px;
	}
	main.delivery .one-tk-desc + div,
	main.delivery .tk-links{
		margin-top: 26px;
	}
}
@media (max-width: 1199px){
	main.delivery h1{
		margin-top: 35px;
	}
}
@media (max-width: 1199px) and (min-width: 768px){
	main.delivery .one-tk-desc{
		margin-bottom: 2px;
	}
}
@media (min-width: 768px){
	main.delivery .one-tk-info > div:first-child{
		text-align: center;
	}
}
@media (max-width: 767px){
	main.delivery h1{
		font-size: 24px;
		margin-bottom: 11px;
	}
	main.delivery p.useful-links{
		margin-top: 48px;
		margin-bottom: 3px;
	}
	main.delivery .tk-info img{
		margin-top: 0;
		margin-bottom: 16.3px;
	}
	main.delivery .one-tk-info{
		margin-bottom: 11px
	}
	main.delivery .tk-links{
		margin-top: 12px;
	}
}
main.c404 p.c404{
	font-size: 256px;
  	font-weight: bold;
  	line-height: 1;
  	color: #ffc93b;
  	margin-top: 73px;
  	margin-bottom: 0;
}
main.c404 h1{
	color: #111111;
	font-weight: 600;
	margin-top: 7px;
}
main.c404 p:not(.c404){
	font-size: 16px;
  	line-height: 1.5;
  	color: #000000;
  	width: 60%;
  	margin: 0 auto 68px;
}
main.c404 .main-menu-desktop{
	margin-bottom: 100px;
}
@media (max-width: 1199px){
	main.c404 p:not(.c404){
		width: 100%;
	}
	main.c404 .main-menu-desktop{
		display: block;
		margin-bottom: 80px;
	}
	main.c404 .main-menu-desktop li + li{
		margin-left: 0;
	}
	main.c404 .row.main-menu-desktop li a{
	    padding: 0 5px 0;
	}
	.row.main-menu-desktop li a span{
	    font-size: 13px;
	}
}
@media (max-width: 767px){
	main.c404{
		padding-bottom: 0;
	}
	main.c404 p.c404{
		font-size: 128px;
		margin-top: 30px;
	}
	main.c404 h1{
		font-size: 20px;
		font-weight: bold;
		margin-top: 14px;
		margin-bottom: 4px;
	}
	main.c404 p:not(.c404){
		font-size: 14px;
		margin-bottom: 28px;
	}
	main.c404 .main-menu-desktop{
		margin-bottom: 84px;
	}
	main.c404 .row.main-menu-desktop li{
		display: list-item;
	}
	main.c404 .row.main-menu-desktop li:first-child a{
		margin-left: 0;
	}
	.row.main-menu-desktop li a span{
	    font-size: 16px;
	    line-height: 2.25;
	}
}
main.details .svg-wrapper{
	text-align: center;
	margin-top: 30px;
	position: relative;
	display: inline-block;
}
.area-hilight{
	position: absolute;
	width: 22px;
	height: 22px;
	background-color: #ffc93b;
	border-radius: 100%;
	cursor: pointer;
}
.area-hilight span{
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	color: #000000;
	font-size: 13px;
  	font-weight: 600;
  	line-height: 1;
}
.area-hilight:hover{
	background-color: #00bc66;
}
.area-hilight:hover span{
	color: #ffffff;
}
main.discounts h1{
	margin-top: 36px;
	margin-bottom: 22px;
}
main.discounts p{
	font-size: 16px;
	line-height: 1.5;
	text-align: left;
}
main.discounts .desc > p{
	margin-bottom: 46px;
}
main.discounts .data h3{
	font-size: 20px;
  	font-weight: bold;
  	line-height: 1.2;
  	color: #000000;
  	margin-top: 0;
	margin-bottom: 14px;
}
.detail-line{
	position: relative;
}
.detail-line + .detail-line{
	margin-top: 12px;
}
main.discounts .data + .data{
	margin-top: 34px;
}
main.discounts .data .name-price{
	position: relative;
	font-size: 16px;
  	line-height: 1.5;
  	display: inline-block;
    width: 600px;
}
main.discounts .data .dotted{
	position: absolute;
	bottom: 5px;
	left: 0;
	width: 100%;
	/*border-bottom: 2px dotted #888888;*/
	background-image: linear-gradient(to right, #888888 30%, rgba(255,255,255,0) 0%);
	background-position: bottom;
	background-repeat: repeat-x;
	background-size: 6px 2px;
	height: 2px;
	z-index: 0;
}
main.discounts .data .name{
	position: relative;
	display: inline;
	max-width: 530px;
	padding-right: 5px;
}
main.discounts .data .name,
main.discounts .data .price{	
	background-color: #ffffff;
	z-index: 1;
}
main.discounts .data .price{
	position: absolute;
	padding-left: 3px;
	padding-right: 13px;
	bottom: 0;
	right: 0;
	text-align: right;
}
main.discounts .data .price:after{
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	background-image: url("../img/rub-text.svg");
	height: 17.5px;
    width: 8px;
}
.detail-line .in-cart,
.detail-line .buy,
.detail-line .delete-cart{
	font-size: 14px;
	line-height: 1.5;
	margin-left: 20px;
	border: none;
	padding: 0 8px 3px;
}
.detail-line .buy{	
	color: #000000;
	background-color: #fff1cc;	
}
.detail-line .in-cart{
	color: #ffffff;
	background-color: #00bc66;
}
.detail-line .delete-cart{
	color: #f52700;
	background-color: #ffffff;
	padding-left: 0;
	padding-right: 0;
}
.subscribe-link h3{
	font-size: 16px;
	font-weight: bold;
  	line-height: 1.5;
  	margin-top: 38px;
}
.subscribe-link p{
	font-size: 14px;
  	line-height: 1.5;
  	margin-bottom: 15px;
}
#main-sale-subscript{
	padding: 0 16px;
	font-size: 14px;
  	font-weight: 600;
  	line-height: 2.57;
  	letter-spacing: 1.5px;
  	border: solid 2px #ffc93b;
  	background-color: transparent;
}
main.discounts .which-discount{
	margin-bottom: 138px;
}
@media (min-width: 1200px){
	main.discounts .second-text-block .col-lg-9{
		padding-right: 100px;
	}
}
@media (max-width: 1199px){
	main.discounts .desc > p{
		margin-bottom: 26px;
	}
	main.discounts .data .name-price{
		width: 470px;
	}
	main.discounts .data .name{
		max-width: 420px;
	}
	main.discounts .sales-subscript-form-wrapper{
		margin-top: 0;
	}
	main.discounts .which-discount {
	    margin-bottom: 95px;
	}
	main.discounts .subscribe-link{
		display: none;
	}
	main.discounts .second-text-block{
		padding-top: 40px;
	}
}
@media (max-width: 767px){
	.detail-line{
		min-height: 85px;
		padding-bottom: 16px;
		border-bottom: 1px solid #e7f0f4;
	}
	.detail-line + .detail-line{
		margin-top: 23px;
	}
	main.discounts .data .name-price{
		width: 100%;
	}
	main.discounts .data .dotted{
		display: none;
	}
	main.discounts .data .name{
		max-width: 50%;
		display: inline-block;
	}
	main.discounts .data .price{
		bottom: auto;
		top: 0;
		text-align: left;
	}
	.detail-line .price,
	.detail-line .buy,
	.detail-line .in-cart{
		right: 45% !important;
		-webkit-transform: translateX(100%);
		        transform: translateX(100%);
	}
	.detail-line .in-cart,
	.detail-line .buy{
		font-weight: 600;
  		line-height: 2.57;
  		letter-spacing: 1.5px;
  		width: 127px;
  		text-align: center;
  		text-transform: uppercase;
  		position: absolute;
		top: 30px;
	}
	.detail-line .delete-cart{
		display: none;
	}
}
main.catalog h1{
	margin-top: 24px;
	margin-bottom: 22px;
}
main:not(.main) #main-search-input{
	width: 686px;
}
.main-input-wrapper + span,
.main-input-wrapper span{
	font-size: 12px;
	line-height: 1.5;
	color: #000000;
}
.ask-price{
	padding: 9px 36px 8px 20px;
	background-color: #fffaea;
	color: #000000;
}
.ask-price h2{
	font-size: 16px;
  	font-weight: bold;
  	line-height: 1.5;
  	margin: 0;
}
.ask-price p{
	font-size: 14px;
  	line-height: 1.5;
  	color: #000000; 	
}
.ask-price #ask-price{
	font-size: 14px;
	font-weight: 600;
	line-height: 2.57;
	letter-spacing: 1.5px;
	padding: 0 16px;
	border: solid 2px #ffc93b;
	background-color: transparent;
	text-transform: uppercase;
}
main.catalog .catalog-links{
	margin-top: 36px;
}
main.catalog .catalog-links h2{
	font-size: 16px;
	font-weight: bold;
	line-height: 1.5;
	color: #000000;
	margin-top: 0;
	margin-bottom: 2px;
}
main.catalog .catalog-links a{
	color: #000000;
}
main.catalog .catalog-links .col-lg-3 > div + div{
	margin-top: 9px;
}
main.catalog .sales-subscript-form-wrapper{
	margin-top: 68px;
}
.win.zapros .form-wrapper{
	padding: 28px 36px 0;
}
.win.zapros h2{
	margin-top: 0;
	margin-bottom: 7px;
	color: #000000;
	font-size: 20px;
  	font-weight: bold;
  	line-height: 1.2;
}
.win.zapros p{
	font-size: 14px;
  	line-height: 1.5;
  	color: #000000;
  	margin-bottom: 31px;
}
.win.zapros span.fieldname{
	display: inline-block;
	width: 96px;
	margin-right: 24px;
	line-height: 1.29;
	color: #000000;
	vertical-align: top;
	margin-top: 10px;
}
.win.zapros form > div + div{
	margin-top: 18px;
}
.win.zapros form input{
	width: 280px;
}
#dropZone{
	width: 280px;
	border: 2px dashed #ffc93b;
	display: inline-block;
	text-align: center;
	padding-top: 15px;
}
#dropZone span{
	display: block;
}
#dropZone a,
#dropZone .drug{
	font-size: 14px;
  	line-height: 1.5;
  	color: #000000;
  	cursor: pointer;
}
#dropZone .size{
	font-size: 10px;
  	line-height: 2.1;
  	color: #000000;
  	margin-top: 2px;
}
#dropZone .loaded-files{
	margin-top: 12px;
}
#dropZone .file-line{
	text-align: left;
	padding: 5px 0 0 10px;
	background-color: #fff3d1;
}
#dropZone .file-line + .file-line{
	padding-top: 0;
}
#dropZone .file-line span{
	display: inline-block;
}
#dropZone .file-name{
    display: inline-block;
    width: 190px;
    overflow: hidden;
    position: relative;
}
#dropZone .file-name:after{
	opacity: 0.9;
	background-image: linear-gradient(to left, #fff1cc, rgba(255, 241, 204, 0));
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	width: 53px;
	height: 18px;
}
#dropZone .file-name span{
	width: 10000px;
	font-size: 12px;
	line-height: 1.5;
	color: #000000;
}
#dropZone .delete-file{
	display: inline-block;
    color: #ff0000;
    vertical-align: top;
    cursor:pointer;
    font-size: 12px;
	line-height: 1.5;
	color: #f52700;
}
.win .popup-footer{
	background-color: rgba(255,250,234, 0.9);
	padding-bottom: 26px;
	padding-left: 156px;
	margin-top: 24px;
}
.win .popup-footer span{
	font-size: 12px;
  	line-height: 1.5;
  	color: #000000;
  	display: block;
  	margin-top: 10px;
  	color: #000000;
}
.win.zapros h2.success{
	font-size: 36px;
  	font-weight: 600;
  	line-height: 1.22;
	color: #00bc66;
	position: relative;
	display: inline-block;
	margin-top: 80px;
	margin-bottom: 10px;
}
.win.zapros h2.success + p{
	font-size: 16px;
	line-height: 1.5;
	color: #000000;
	margin-bottom: 19px;
}
.yellow-transparent{
	font-size: 14px;
	font-weight: 600;
	line-height: 2.57;
	letter-spacing: 1.5px;
	padding: 0 20px;
	border: solid 2px #ffc93b;
	background-color: transparent;
}
.win.zapros.success{
	padding-bottom: 149px;
}
@media (min-width: 1200px){
	.ask-price p{
		width: 70%;
    	display: inline-block;
	}
	.ask-price #ask-price{
		float: right;
	}
	.ask-price{
		width: 66%;
	}
	main.catalog .second-text-block .col-lg-3:after{
		position: absolute;
		z-index: -1;
		content: "";
		opacity: 0.5;
		background-image: url(../img/engine__yellow.png);
		width: 776px;
		height: 591px;
		top: 0;
		right: -152%;
	}
}
@media (min-width: 768px){
	.popup .win.zapros{
		width: 520px;
	}
	#dropZone .load-xs{
		display: none;
	}

}
@media (max-width: 1199px){
	.ask-price{
		margin-top: 20px;
		padding-bottom: 24px;
	}
	main:not(.main) #main-search-input{
		width: 567px;
	}
}
@media (max-width: 767px){
	.ask-price{
		padding-bottom: 36px;
	}
	main:not(.main) #main-search-input{
		width: 235px;
	}
	main.catalog .tractor-models{
		margin-top: 23px;
	}
	main.catalog .ask-price{
		margin-top: 30px;
	}
	main.catalog .catalog-links{
		margin-top: 30px;
	}
	main.catalog .catalog-links .col-lg-3 + .col-lg-3{
		margin-top:  9px;
	}
	main.catalog .sales-subscript-form-wrapper{
		margin-top: 62px;
	}
	.win.zapros{
		min-height: 645px;
	}
	.win.zapros .form-wrapper,
	.win.zapros .popup-footer{
		padding-left: 18px;
		padding-right: 18px;
	}
	.win.zapros .popup-footer{
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		padding-bottom: 46px;
	}
	.win.zapros form input,
	.win.zapros .yellow-btn{
		width: 100%;
	}
	.win.zapros p{
		margin-bottom: 24px;
	}
	.win.zapros span.fieldname{
		margin-bottom: 4px;
	}
	#dropZone{
		padding-top: 24px;
	}
	#dropZone .loaded-files{
		min-height: 30px;
	}
	#dropZone .drug{
		display: none;
	}
}
main.search{
	padding-bottom: 122px;
}
main.search h1{
	margin-top: 39px;
	margin-bottom: 22px;
}
.check-type{
	margin-top: 15px;
}
.table-header div,
.search-result .name-price,
.search-result .name-price div,
.detail-list .name-price,
.detail-list .name-price div{
	display: inline-block;
	vertical-align: top;
}
main.search .search-result{
	margin-top: 40px;
}
.search-result > p{
	font-size: 14px;
  	line-height: 1.5;
  	color: #000000;
  	margin-bottom: 12px;
}
.search-result .code{
	width: 150px;
}
.search-result .name{
	width: 200px;
}
.search-result .models{
	width: 210px;
}
.search-result div.count{
	width: 150px;
}
.search-result .table-header,
.detail-list .table-header{
	opacity: 0.9;
  	font-size: 16px;
  	font-weight: bold;
  	line-height: 1.5;
  	color: #000000;
  	border-bottom: 1px solid #b4c1c8;
  	margin-bottom: 10px;
}
.search-result .detail-line,
.search-result .detail-line a,
.detail-list .detail-line,
.detail-list .detail-line a,
.cart .detail-line,
.cart .detail-line a{
	opacity: 0.9;
	font-size: 16px;
  	line-height: 1.31;
  	color: #000000;
}
.search-result span.count,
.search-result span.count + span,
.detail-list span.count,
.detail-list span.count + span,
.cart span.count,
.cart span.count + span{
	font-size: 12px;
  	line-height: 1.75;
  	color: #888888;
}
.search-result span.count,
.detail-list span.count,
.cart span.count{
	display: inline-block;
	margin-left: 5px;
}
@media (min-width: 1200px){
	.search-result span.count,
	.search-result span.count + span,
	.detail-list span.count,
	.detail-list span.count + span{
		display: none;
	}
}
@media (max-width: 1199px){
	.search-result div.count,
	.detail-list div.count{
		display: none;
	}
	.search-result .code{
		width: 140px;
	}
	.search-result .name{
		width: 225px;
	}
	.search-result .models{
		width: 140px;
	}
	main.search #main-search-input{
		width: 80%;
	}
}
@media (max-width: 1199px) and (min-width: 992px){
	main.search .search-result .name {
	    width: 300px;
	}
	main.search .search-result .code {
	    width: 200px;
	}
	main.search .search-result .models{
		width: 260px;
	}
}
@media (max-width: 767px){
	main.search{
		padding-bottom: 119px;
	}
	main.search .search-result{
		margin-top: 32px;
	}
	.search-result > p{
		margin-bottom: 20px;
	}
	.table-header,
	.search-result .name-price .models{
		display: none;
	}
	.search-result .detail-line,
	.detail-list .detail-line{
		min-height: auto;
	}
	.search-result .detail-line a,
	.detail-list .detail-line a{
		font-size: 14px;
		font-weight: bold;
		line-height: 1.5;
	}
	.search-result .detail-line,
	.search-result .detail-line a,
	.detail-list .detail-line,
	.detail-list .detail-line a{
		opacity: 1;
	}
	.search-result .name-price,
	.detail-list .name-price{
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		    -ms-flex-direction: column;
		        flex-direction: column;
		width: 50%;
		margin-bottom: 3px;
	}
	.search-result .name {
	    width: 100%;
	}
	.search-result .name,
	.detail-list .name{
		-webkit-box-ordinal-group: 2;
		    -ms-flex-order: 1;
		        order: 1;
	}
	.search-result .code,
	.detail-list .code{
		-webkit-box-ordinal-group: 3;
		    -ms-flex-order: 2;
		        order: 2;
		margin-top: 3px;
	}
	.search-result .name,
	.search-result .code,
	.detail-list .name,
	.detail-list .code{
		width: auto;
	}
	.search-result .buy,
	.search-result .in-cart,
	.detail-list .buy,
	.detail-list .in-cart{
		top: 0;
	}
}
main.catalog-model{
	padding-top: 25px;
}
main.catalog-model h1{
	margin-top: 0;
	margin-bottom: 34px;
}
main.catalog-model .main-input-wrapper,
.model-catalog{
	display: inline-block;
	vertical-align: top;
}
.model-catalog{
	position: relative;
	padding-left: 40px;
	margin-left: 50px;
	margin-top: 5px;
}
.model-catalog:before{
	content: "";
	position: absolute;
	top: 5px;
	left: 0;
	width: 32px;
	height: 32px;
	background-image: url("../img/icon-search-document-32.svg");
}
.model-catalog .name{
	display: block;
	color: #000000;
}
.model-catalog span.logo{
	margin-left: 0;
}
.cat-names{
	padding-top: 35px;
	padding-bottom: 24px;
}
.cat-names:not(.category){
	border-bottom: 1px solid #e7f0f4;
}
.cat-names .main-name{
	font-size: 20px;
	font-weight: bold;
	line-height: 1.2;
	color: #000000;
}
.cat-names .subcat{
	margin-top: 11px;
}
.cat-names .subcat > div{
	margin-bottom: 8px;
}
.cat-names .subcat a{
	font-size: 14px;
	line-height: 1.5;
	color: #000000;
}
.discounts-cat > div{
	padding-right: 120px;
	padding-bottom: 4px;
}
.discounts-cat img{
	width: 65px;
}
.discounts-cat h2{
	margin-top: 16px;
	margin-bottom: 2px;
	font-size: 20px;
	font-weight: bold;
	line-height: 1.2;
	color: #000000;
}
.discounts-cat p{
	font-size: 15px;
	line-height: 1.6;
	color: #000000;
	margin-bottom: 18px;
}
.discounts-cat .yellow-btn{
	margin-top: 0;
	border: none;
}
.discounts-cat a.yellow-btn{
	display: inline-block;
}
.discounts-cat .black-btn{
	border: none;
	font-size: 14px;
  	font-weight: 600;
  	line-height: 2.57;
  	letter-spacing: 1.5px;
  	padding: 0 16px;
}
main.catalog-model .ask-price{
	margin-top: 23px;
	margin-bottom: 5px;
}
/*Каталог деталь*/
main.catalog-model h1 span{
	font-size: 24px;
	line-height: 1.33;
	color: #888888;
	font-weight: normal;
	display: inline-block;
	margin-left: 10px;
}
main.catalog-model p.uses{
	font-size: 16px;
	line-height: 1.5;
	text-align: left;
	color: #000000;
	margin-bottom: 17px;
}
div.uses + div.uses{
	padding-top: 31px;
}
div.uses h2{
	font-size: 24px;
	font-weight: bold;
	line-height: 1.33;
	color: #000000;
	margin-bottom: 12px;
	margin-top: 17px;
}
div.uses .cat-names:nth-child(2){
	padding-top: 0;
}
.ask-price-wrapper .ask-price,
.detail-list-sidebar .ask-price{
	width: auto;
	padding-bottom: 24px;
}
.ask-price-wrapper .ask-price p,
.detail-list-sidebar .ask-price p{
	width: auto;
}
.ask-price-wrapper .ask-price #ask-price,
.detail-list-sidebar .ask-price #ask-price{
	float: none;
}
.call-price{
	width: 273px;
	margin-bottom: 60px;
}
.call-price .price{
	font-size: 24px;
	font-weight: bold;
	line-height: 1.33;
	color: #000000;
	position: relative;
}
.call-price .price:after,
.call-price .price:before{
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	width: 73px;
	height: 2px;
	background-color: #f62600;
}
.call-price .price:after{
	-webkit-transform: translate(-50%, -50%) rotate(15deg);
	        transform: translate(-50%, -50%) rotate(15deg);
}
.call-price .price:before{
	-webkit-transform: translate(-50%, -50%) rotate(-15deg);
	        transform: translate(-50%, -50%) rotate(-15deg);
}
.call-price .price-rubles{
	font-size: 16px;
	font-weight: bold;
	line-height: 1.5;
	color: #111111;
	display: inline-block;
	margin-left: 10px;
}
.call-price p{
	margin-top: 2px;
	margin-bottom: 3px;
	font-size: 14px;
	line-height: 1.5;
	color: #000000;
}
.call-price .buy{
	border: none;
}
.call-price .in-cart{
	font-size: 14px;
	font-weight: 600;
	line-height: 2.57;
	letter-spacing: 1.5px;
	color: #ffffff;
	padding: 0 16px;
	border: none;
	background-color: #00bc66;
	box-shadow: 4px 4px 0 0 rgba(0, 0, 0, 0.2);
	text-transform: uppercase;
	margin-top: 19px;
}
.call-price .delete-cart{
	font-size: 16px;
	line-height: 1.5;
	color: #f52700;
	background-color: transparent;
	border: none;
	margin-left: 28px;
}
/*Каталог модели без каталога*/
.model-catalog-big a.yellow-btn{
	display: inline-block;
	margin-top: 0;
	text-transform: uppercase;
	padding: 6px 20px
}
.model-catalog-big a.yellow-btn span{
	padding-left: 20px;
	position: relative;
}
.model-catalog-big a.yellow-btn span:before{
	content: "";
	position: absolute;
	top: 3.5px;
	left: 0;
	width: 12px;
	height: 13px;
	background-image: url("../img/icon-download.svg");
}
main.without-catalog .ask-price{
	margin-top: 30px;	
}
.without-catalog .sales-subscript-form-wrapper{
	margin-top: 68px;
}
@media (min-width: 1200px){
	main.catalog-model .main-input-wrapper{
		margin-bottom: 12px;
	}
	main.catalog-model .container{
		position: relative;
	}
	main.catalog-model:not(.unit) .container:after{
		position: absolute;
		z-index: -1;
		content: "";
		opacity: 0.5;
		background-image: url(../img/engine__yellow.png);
		width: 776px;
		height: 591px;
		top: 29px;
    	right: -43%;
	}
	.discounts-cat{
		padding-top: 57px;
	}
	.ask-price-wrapper .ask-price{
		margin-right: -15px;
		margin-left: -15px;
	}
}
@media (max-width: 1199px){
	.model-catalog{
		margin-left: 0;
		margin-top: 18px;
		margin-bottom: 8px;
	}
	main.catalog-model .main-input-wrapper{
		display: block;
	}
	.discounts-cat > div + div{
		margin-top: 50px;
	}
	.discounts-cat{
		padding-top: 30px;
	}
	.detail-info{
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		    -ms-flex-direction: column;
		        flex-direction: column;
	}
	.ask-price-wrapper{
		-webkit-box-ordinal-group: 2;
		    -ms-flex-order: 1;
		        order: 1;
	}
	.applicability{
		-webkit-box-ordinal-group: 3;
		    -ms-flex-order: 2;
		        order: 2;
	}
	.call-price{
		margin-bottom: 33px;
	}
}
@media (max-width: 1199px) and (min-width: 768px){
	.discounts-cat > div{
		padding-left: 120px;
		position: relative;
	}
	.discounts-cat img{
		position: absolute;
		top: 0;
		left: 0;
		width: 96px;
	}
}
@media (min-width: 768px){
	.model-catalog-big span.logo{
		margin-left: 24px;
	}
}
@media (max-width: 767px){
	main.catalog-model .ask-price{
		margin-left: -18px;
		margin-right: -18px;
	}
	.discounts-cat > div{
		padding-right: 0;
	}
	main.catalog-model h1 span{
		margin-left: 0;
		font-size: 14px;
  		line-height: 1.71;
  		display: block;
	}
	.model-catalog-big a.yellow-btn{
		margin-bottom: 16px;
	}
	.without-catalog .second-text-block .col-lg-9{
		margin-top: 42px;
	}
}
/*main.unit.affix-buffer{
	margin-top: -35px;
}*/
.unit-wrapper{
	border: solid 1px #979797;
	height: 998px;
	overflow: hidden;
}
.unit-wrapper .unit-img{
	position: relative;
}
.detail-info-popup{
	background-color: #000000;
	width: 240px;
	position: absolute;
	padding: 10px 6px 22px 41px;
	z-index: 150;
	box-shadow: 5px 5px 0 0 rgba(0, 0, 0, 0.3);
}
.detail-info-popup:after  {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 115px;
    width: 0px;
    height: 0px;
    border-top: 10px solid #000000;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
}
.detail-info-popup a.name{
	font-size: 14px;
	font-weight: bold;
	line-height: 1.29;
	color: #ffffff;
	display: inline-block;
	margin-bottom: 7px;
	text-decoration: underline;
}
.detail-info-popup span.code{
	font-size: 14px;
	line-height: 1.29;
	color: #ffffff;
	display: block;
	margin-bottom: 8px;
}
.detail-info-popup span.number{
	font-size: 14px;
	font-weight: bold;
	line-height: 1.71;
	color: #ffffff;
	position: absolute;
	left: 16px;
	top: 8px;
}
.detail-info-popup .yellow-btn{
	margin-top: 0;
    border: none;
    font-size: 12px;
    line-height: 1;
    letter-spacing: 1px;
    padding: 6px 10px;
}
.detail-info-popup .in-cart{
	font-size: 12px;
	font-weight: 600;
	line-height: 1;
	letter-spacing: 1px;
	color: #ffffff;
	padding: 6px 10px;
	background-color: #00bc66;
	border:none;
	text-transform: uppercase;
}
.detail-info-popup .delete-cart{
	background: transparent;
	font-size: 0;
	border: none;
	background-image: url('../img/icon-price-delete.svg');
	height: 24px;
	width: 24px;
	vertical-align: top;
}
.detail-list h2{
	font-size: 24px;
	font-weight: bold;
	line-height: 1.33;
	color: #000000;
	margin-bottom: 13px;
	margin-top: 0;
}
.detail-list .call-tel-wrng{
	font-size: 14px;
	line-height: 1.5;
	color: #000000;
	position: relative;
	padding-left: 22px;
	margin-bottom: 25px;
}
.detail-list .call-tel-wrng:before{
	background-image: url('../img/icon-warning-red-16.svg');
	content: '';
	position: absolute;
	top: 3.5px;
	left: 0;
	width: 16px;
	height: 16px;
}
.detail-list .number{
	width: 36px;
	display: inline-block;
	vertical-align: top;
}
.detail-list .number .area-hilight{
	position: relative;
}
.detail-list .code{
	width: 160px;
}
.detail-list  .name{
	width: 250px;
}
.detail-list div.count{
	width: 120px;
}
main.catalog-model .detail-list-sidebar .ask-price{
	margin-bottom: 32px;
}
.detail-list-sidebar .row p{
	padding-left: 32px;
	position: relative;
	font-size: 14px;
	line-height: 1.5;
	color: #000000;
}
.detail-list-sidebar .row p:before{
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	height: 16px;
	width: 16px;
}
.detail-list-sidebar .row p.subscribe:before{
	background-image: url('../img/icon-percent-16.svg');
}
.detail-list-sidebar .row p.send:before{
	background-image: url('../img/icon-list-16.svg');
}
.detail-list-sidebar .row p.search:before{
	background-image: url('../img/icon-search-doc-16.svg');
}
.detail-list-sidebar .black-btn{
	border:none;
	font-size: 14px;
	font-weight: 600;
	line-height: 2.57;
	letter-spacing: 1.5px;
	padding: 0 16px;
	margin-left: 32px;
}
.detail-list-sidebar a{
	 margin-left: 32px;
	display: inline-block;
	color: #000000;
}
.area-hilight.green{
	background-color: #00bc66;
}
.area-hilight.green span{
	color: #ffffff;
}
@media (min-width: 1200px){
	.unit-wrapper{
		box-shadow: 5px 5px 0 0 rgba(0, 0, 0, 0.3);
	}
	.after-unit{
		margin-top: 69px;
	}
	.detail-list .table-header{
		margin-right: 180px;
	}
	.detail-list-sidebar{
		padding-left: 0;
	}
	.detail-list-sidebar .row > div + div{
		margin-top: 35px;
	}
}
@media (max-width: 1199px) and (min-width: 768px){
	.unit-wrapper{
    	height: 672px;
	}
	.unit-wrapper,
	.unit-wrapper + map + .after-unit .ask-price{
		margin-left: -48px;
    	margin-right: -48px;
	}
	.detail-list{
		margin-top: 65px;
	}
}
@media (max-width: 1199px){
	.unit-wrapper{
		margin-top: 35px;
	}
	main.catalog-model .after-unit .ask-price{
		margin-top: 0;
	}
	.detail-list .code{
		width: 148px;
	}
	.detail-list  .name{
		width: 295px;
	}
	.after-unit{
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		    -ms-flex-direction: column;
		        flex-direction: column;
	}
	.detail-list-sidebar{
		-webkit-box-ordinal-group: 2;
		    -ms-flex-order: 1;
		        order: 1;
	}
	.detail-list{
		-webkit-box-ordinal-group: 3;
		    -ms-flex-order: 2;
		        order: 2;
	}
	main.catalog-model .detail-list-sidebar .ask-price{
		margin-bottom: 46px;
	}
}
@media (max-width: 767px){
	.unit-wrapper{
    	height: 382px;
	}
	.unit-wrapper,
	.ask-price{
		margin-left: -18px;
    	margin-right: -18px;
	}
	.detail-list .number{
		display: none;
	}
	.detail-list-sidebar .row{
		display: none;
	}
	main.catalog-model .detail-list-sidebar .ask-price{
		margin-bottom: 52px;
	}
	.detail-list  .name{
		width: 100%;
	}
}
main.order h1{
	margin-top: 40px;
	margin-bottom: 22px;
}
.order-list .table-header,
.order-list .detail-line{
	font-size: 0;
}
.order-list .table-header > div{
	font-size: 16px;
	font-weight: bold;
	line-height: 1.5;
	color: #000000;
}
.order-list .table-header .quantity{
	padding-left: 23px;
}
.order-list .table-header .price{
	position: relative;
}
.order-list .table-header .price:after{
	background-image: url("../img/₽.svg");
	width: 9px;
	height: 12px;
	content: '';
	position: absolute;
	top: 6px;
	right: -10px;
}
.order-list .table-header{
	border-bottom: 1px solid #000000;
	margin-bottom: 10px;
}
.order-list .detail-line + .detail-line{
	margin-top: 13px;
}
.order-list .detail-line div:not(.name-price){
	font-size: 16px;
}
.order-list .number{
	width: 40px;
	display: inline-block;
	vertical-align: top;
}
.order-list .code{
	width: 140px;
}
.order-list .name{
	width: 203px;
}
.order-list div.count{
	width: 130px;
}
.order-list span.count,
.order-list span.count + span{
	display: none;
}
.order-list .quantity{
	width: 155px;
}
.order-list .cart .quantity{
	font-size: 0;
}
.order-list .cart .price{
	width: 95px;
}
.order-list .quantity button{
	border: none;
	background-color: transparent;
	vertical-align: top;
	padding: 0;
}
.order-list .quantity button img{
	width: 24px;
	height: 24px;
}
.order-list .quantity input{
	width: 56px;
	height: 24px;
	border: solid 2px #ffc93b;
	font-size: 16px;
	padding: 0 8px;
	border-radius: 0;
}
.order-list .delete{
	border: none;
	background-color: transparent;
	padding: 0;
	color: #f52700
}
.order-list .delete span{
	font-size: 16px;
}
main.order .text-doc{
	line-height: 1.5;
	color: #000000;
	margin-top: 37px;
}
main.order #zapros{
	width: 520px;
	background-color: #fffaea;
	margin-top: 41px;
}
main.order #zapros.error{
	background-color: #ffffff;
}
.increment:disabled{
	opacity: 0.3;
}
.empty-cart{
	border: dashed 2px #ffc93b;
	width: 540px;
	margin: 0 auto;
	text-align: center;
	padding: 35px 50px 84px;
	margin-top: 100px;
}
.empty-cart h2{
	font-size: 24px;
	font-weight: bold;
	line-height: 1.33;
	color: #ffc93b;
	margin-bottom: 11px;
}
.empty-cart p{
	font-size: 16px;
	line-height: 1.5;
	color: #000000;
	margin-bottom: 30px;
}
.empty-cart .yellow-btn{
	border: none;
}
.empty-cart a.yellow-btn{
	display: inline-block;
}
main.order #zapros h2.error{
	color: #f52700;
}
main.order #zapros.success{
	margin: 0 auto;
	background-color: #ffffff;
	text-align: center;
}
@media (min-width: 1200px){
	.order-list .delete img{
		display: none;
	}
	.order-list .table-header{
		width: 830px;
	}
}
@media (min-width: 768px){
	.order-list .cart div{
		display: inline-block;
		vertical-align: top;
	}
	.empty-cart .yellow-btn + .yellow-btn{
		margin-left: 30px;
	}
}
@media (max-width: 1199px) and (min-width: 768px){
	.order-list span.count,
	.order-list span.count + span{
		display: inline-block;
	}
	.order-list .delete span{
		display: none;
	}
	main.order #zapros{
		margin-top: 46px;
	}
}
@media (max-width: 1199px){
	.order-list div.count{
		display: none;
	}
	.order-list .number{
		width: 30px;
	}
	.order-list .code{
		width: 130px;
	}
	.order-list .name{
		width: 250px;
	}
	.order-list .quantity{
		width: 140px;
	}
}
@media (max-width: 767px){
	main.order h1{
		margin-top: 35px;
		margin-bottom: 13px;
	}
	.order-list .detail-line{
		padding-bottom: 4px;
	}
	.order-list .delete img,
	.order-list .detail-line .quantity button{
		display: none;
	}
	.order-list .detail-line .name-price,
	.order-list .detail-line .name-price > div{
		margin-top: 3px;
	}
	.order-list .detail-line .number,
	.order-list .detail-line .code,
	.order-list .detail-line .name,
	.order-list .detail-line .count,
	.order-list .detail-line .quantity,
	.order-list .detail-line .price,
	.order-list .delete{
		padding-left: 157px;
		position: relative;
	}
	.order-list .detail-line .number:before,
	.order-list .detail-line .code:before,
	.order-list .detail-line .name:before,
	.order-list .detail-line .count:before,
	.order-list .detail-line .quantity:before,
	.order-list .detail-line .price:before{
		position: absolute;
		top: 0;
		left: 0;
		font-weight: bold;
	}
	.order-list .detail-line .number:before{
		content: '№';
	}
	.order-list .detail-line .code:before{
		content: 'Код';
	}
	.order-list .detail-line .name:before{
		content: 'Наименование';
	}
	.order-list .detail-line .count:before{
		content: 'Комплект';
	}
	.order-list .detail-line .quantity:before{
		content: 'Количество';
	}
	.order-list .detail-line .price:before{
		content: 'Цена, ₽';
	}
	.order-list .detail-line .price{
		right: auto !important;
		-webkit-transform: none;
		        transform: none;
	}
	.order-list .detail-line div:not(.name-price),
	.order-list .detail-line a,
	.order-list .detail-line input{
		font-size: 14px;
		line-height: 1.5;
	}
	.order-list .detail-line input{
		line-height: 1.45;
		height: auto;
	}
	.order-list .detail-line div.count{
		display: block;
	}
	.order-list .delete{
	    margin-top: 14px;
	}
	.order-list .delete span{
		text-transform: uppercase;
	    line-height: 1.71;
	    font-size: 14px;
	    letter-spacing: 1.5px;
	    font-weight: 600;
	}
	main.order #zapros{
		margin-top: 28px;
		width: auto;
	}
	/*main.order #zapros:not(.error){
		margin-left: -18px;
		margin-right: -18px;
	}*/
	main.order .win.zapros{
		min-height: auto;
	}
	main.order .win.zapros .popup-footer{
		position: relative;
	}
	.empty-cart{
		width: auto;
		margin-top: 29px;
		padding: 24px 18px 48px;
	}
	.empty-cart .yellow-btn{
		width: 100%;
	}
	main.order #zapros.success .form-wrapper{
		padding: 0;
	}
	main.order #zapros.success h2{
		font-size: 24px;
		line-height: 1.33;
	}
	main.order #zapros.success h2:after{
		background-image: url('../img/icon-order-success-small.svg');
		height: 23px;
		width: 24px;
		right: -30px;
	}
	main.order #zapros.success p{
		font-size: 14px;
	}
}