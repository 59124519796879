header{
	padding-top: 47px;
}
header .row{
	margin-left: 0px;
	margin-right: 0px;
}
.city-pickup-wrapper{
	position: relative;
	display: inline-block;
}
.city-btn{
	position: relative;
	background-color: #fff;
	border: none;
	padding: 0;
	padding-left: 14px;
}
.city-btn:before{
	content: "";
	background-image: url("../img/icon-location.svg");
	position: absolute;
	left: 0;
	top: 3px;
	height: 13px;
	width: 9px;
}
.city-btn span{
	font-size: 14px;
	border-bottom: 1px dashed rgba(0, 0, 0, 0.3);
}
.city-btn:hover span{
	border-color: #000000;
}
.checkout-link{
	position: relative;
	display: inline-block;
	font-size: 14px;
	font-weight: 600;
	line-height: 2.57;
	letter-spacing: 1.5px;
	text-transform: uppercase;
	color: #000000;
	padding: 0 19px 0 33px;
	margin-right: 38px;
	margin-top: 7px;
	vertical-align: top;
}
.checkout-link.shaker {
  animation: shake 0.5s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}
@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
#fixed-panel .checkout-link{
	padding-right: 10px;
}
.cart-in{
	position: absolute;
	right: 0;
	top: 0;
	background-color: #f62600;
	width: 16px;
	height: 16px;
    transform: translate(50%, -50%);
    border-radius: 100%;
}
.btn-main-menu .cart-in{
	right: 7px;
    top: -4px;
    transform: none;
}
#fixed-panel .cart-in{
	top: 7px;
}
.cart-in span{
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-45%, -50%);
	font-size: 10px;
	color: #ffffff;
	line-height: 1;
}
.btn-main-menu .cart-in span{
	transform: translate(-50%, -50%);
}
.header-right .checkout-link{
	border: solid 2px #ffc93b;
}
#dropd-main-menu .checkout-link{
	border: solid 2px #000000;
}
.checkout-link:before{
	content: "";
	background-image: url("../img/icon-cart-black-16.svg");
	position: absolute;
	left: 10px;
	top: 9px;
	height: 16px;
	width: 16px;
}
a.header-tel{
  	font-size: 20px;
  	font-weight: 600;
  	color: #000000;
  	display: inline-block;
  	margin-top: 14px;
}
.callback-link{
	font-size: 14px;
	line-height: 1.5;
	background-color: transparent;
	padding: 0;
	border-top: none;
	border-left: none;
	border-right: none;
	border-radius: 0;
	margin-top: 6px;
}
.header-left{
	float: left;
}
.header-left .dropdown{
	display: inline-block;
}
.header-left .city-btn{
	margin-top: 21px;
	margin-left: 21px;
	/*Для #city-popup*/
	margin-right: 105px;
}
.header-right{
	float: right;
}
.header-right > div{
	display: inline-block;
	text-align: right;
}
.btn-main-menu{
	position: relative;
	background-color: transparent;
	border: none;
	height: 35px;
}
.btn-main-menu .menu-logo{
	position: relative;
	height: 0;
	width: 42px;
}
.btn-main-menu .menu-logo span{
	transition: all 400ms ease 0ms;
	display: block;
	background-color: #000000;
	height: 3px;
	position: absolute;
	left: 0;
	top: 0;
}
.btn-main-menu .menu-logo:not(.open) span:first-child{
	width: 33px;
	top: -8px;
}
.btn-main-menu .menu-logo:not(.open) span:nth-child(2){
	width: 22px;
}
.btn-main-menu .menu-logo:not(.open) span:last-child{
	width: 27px;
	top: 8px;
}
.btn-main-menu .menu-logo.open span:first-child{
	transform: rotate(45deg);
	width: 42px;
}
.btn-main-menu .menu-logo.open span:last-child{
	transform: rotate(-45deg);
	width: 42px;
}
.row.main-menu-desktop li{
	display: inline-block;
}
.row.main-menu-desktop li a,
.row.main-menu-desktop li > span{
	padding: 0 9px 0;
	line-height: 34px;
	display: inline-block;
}
.row.main-menu-desktop li:first-child a{
	margin-left: -9px;
}
.main-menu-desktop li a span,
.main-menu-desktop li > span{
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 1.5px;
	line-height: 1.8;
	color: #000000;
}
.main-menu-desktop li > span{
	display: inline-block;
	cursor: default;
}
.main-menu-desktop li a span{	
	border-bottom: solid 2px rgba(0, 0, 0, 0.3);
}
.row.main-menu-desktop li a span,
.row.main-menu-desktop li > span{
	font-size: 14px;
}
.row.main-menu-desktop li + li{
	margin-left: 4px;
}
.row.main-menu-desktop li > a:focus,
.row.main-menu-desktop li > a:hover,
.row.main-menu-desktop li.active > span{
	background-color: #ffc93b;
}
.row.main-menu-desktop li > a:focus span,
.row.main-menu-desktop li > a:hover span{
	border-bottom: none;
}
#dropd-main-menu{
	position: absolute;
	background-image: linear-gradient(to top, #ffc838, #ffd35d);
	background-color: #ffc838;
	width: 100%;
	top: 72px;
	z-index: 200;
	visibility: hidden;
	transform: translateY(-100%);
	transition: all 200ms cubic-bezier(0, 0.9, 0.4, 1);

}
#dropd-main-menu.cubic{
	transition-timing-function: cubic-bezier(0.26, 0.92, 0.34, 1);
}
#dropd-main-menu.open{
	visibility: visible;
	transform: translateY(0);
	box-shadow: 0 25px 36px 0 rgba(0, 0, 0, 0.57);
}
#dropd-main-menu .container{
	margin: 30px auto 0;
}
#dropd-main-menu .city-list{
	margin-top: 30px;
	margin-bottom: 19px;
	padding-left: 15px;
}
.main-menu-desktop li a span,
.main-menu-desktop li > span{
	font-size: 16px;
}
.city-list li a,
.city-list li a:focus{
	font-size: 14px;
	color: #000000;
	text-decoration: underline;
	text-decoration-color: rgba(0, 0, 0, 0.7);
}
.city-list li.active a,
.city-list li a:hover{
	color: rgba(0, 0, 0, 0.5);
	text-decoration: none;
}
#city-popup{
	position: absolute;
	left: 19px;
	top: 7px;
	background-color: #ffc93b;
	padding: 14px 27px 32px 16px;
	z-index: 100;
	box-shadow: 4px 4px 0 0 rgba(0, 0, 0, 0.2);
}
#city-popup li a{
	font-size: 14px;
	color: #000000;
	border-bottom: solid 1px rgba(0, 0, 0, 0.3);
}
#city-popup li a:hover{
	border-color: #000000;
}
#city-popup li.active a{
	border-bottom-style: dashed;
}
#city-popup li + li{
	margin-top: 9px;
}
#fixed-panel{
	background-color: #ffc93b;
	box-shadow: 0 12px 14px 0 rgba(0, 0, 0, 0.13);
	height: 0;
	visibility: hidden;
}
#fixed-panel.affix-top *{
	height: 0;
	margin: 0;
	padding: 0;
}
#fixed-panel.affix{
	visibility:	visible;
	height: auto;
	padding: 7px 0 5px;
	top: 0;
	width: 100%;
	z-index: 300;
}
#fixed-panel .checkout-link{
	margin-top: 0;
	border: none;
}
#fixed-panel .breadcrumbs{
	float: left;
	margin-bottom: 0;
	margin-top: 7px;
}
#fixed-panel .header-tel{
	margin-top: 5px;
	font-size: 16px;
  	line-height: 1.5;
}
@media (min-width: 1200px){
	.btn-main-menu{
		display: none;
	}
}
@media (min-width: 768px){
	.main-menu-desktop li + li{
		margin-top: 12px;
	}
	.city-list li{
		display: inline-block;
	}
	.city-list li + li{
		margin-left: 15px;
	}
}
@media (max-width: 1199px){
	.header-left .city-btn,
	.header-left + .header-right .checkout-link,
	.header-right .callback-link,
	.header-left + .header-right .header-tel,
	.row.main-menu-desktop{
		display: none;
	}
	.header-left img{
		height: 40px;
	}
	header{
		padding-top: 13px;
		padding-bottom: 19px;
		background-image: linear-gradient(to top, #ffc838, #ffd35d);
		background-color: #ffc838;
		z-index: 250;
		position: relative;
	}
	#fixed-panel .breadcrumbs{
		display: none;
	}
	#fixed-panel .header-right{
		float: none;
	}
	#fixed-panel .header-tel{
		float: right;
	}
}
@media (max-width: 767px){
	header{
		padding: 14px 0;
	}
	.btn-main-menu .menu-logo-close{
	    margin-top: 6px;
	}
	.btn-main-menu .menu-logo-open{
		width: 36px;
    	height: 26px;
	}
	.btn-main-menu .menu-logo-open span{
		width: 35px;
	}
	.btn-main-menu{
		height: 28px;
	}
	.btn-main-menu .menu-logo.open span:first-child,
	.btn-main-menu .menu-logo.open span:last-child{
		width: 35px;
	}
	.header-left img{
		height: 32px;
	}
	#dropd-main-menu{
		top: 60px;
		height: 100%;
		overflow-y: scroll;
	}
	#dropd-main-menu .container{
		margin-top: 12px;
	}
	.main-menu-desktop li a span{
		font-size: 16px;
		line-height: 2.25;
		letter-spacing: 2px;
	}
	.checkout-link{
		margin-top: 32px;
	}
	a.header-tel{
		margin-top: 19px;
	}
	#dropd-main-menu .city-list{
		margin-top: 24px;
	}
	.city-list li + li{
		margin-top: 9px;
	}
	#fixed-panel .header-tel{
		display: none;
	}
}
