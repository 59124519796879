main.details .svg-wrapper{
	text-align: center;
	margin-top: 30px;
	position: relative;
	display: inline-block;
}
.area-hilight{
	position: absolute;
	width: 22px;
	height: 22px;
	background-color: #ffc93b;
	border-radius: 100%;
	cursor: pointer;
}
.area-hilight span{
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: #000000;
	font-size: 13px;
  	font-weight: 600;
  	line-height: 1;
}
.area-hilight:hover{
	background-color: #00bc66;
}
.area-hilight:hover span{
	color: #ffffff;
}