main.handbook h1{
	margin-top: 40px;
	margin-bottom: 22px;
}
main.handbook p{
	font-size: 16px;
	line-height: 1.5;
}
ol{
	padding-left: 20px;
}
ol li,
ol li a{
	font-size: 16px;
	line-height: 1.5;
	color: #000000;
}
ol li + li{
	margin-top: 8px;
}
@media (max-width: 1999px) and (min-width: 768px){
	main.handbook{
		padding-bottom: 222px;
	}
}
@media (max-width: 767px){
	main.handbook h1{
		margin-top: 35px;
		margin-bottom: 11px;
	}
	ol li + li{
		margin-top: 9.5px;
	}
	main.handbook ol{
		padding-left: 25px;
	}
}