main.delivery h1{
	margin-top: 40px;
	margin-bottom: 22px;
}
main.delivery h2,
main.delivery p{
	color: #000000;
}
main.delivery .container > p{
	font-size: 16px;
	line-height: 1.5;
}
main.delivery .container > p + p{
	margin-top: 25px;
}
main.delivery p.useful-links{
	font-weight: bold;
	margin-bottom: 3px;
}
main.delivery ul.useful-links li{
	margin-bottom: 9px;
}
main.delivery ul.useful-links li a{
  	line-height: 1.5;
  	color: #000000;
}
main.delivery .tk-info{
	margin-top: 62px;
}
main.delivery .one-tk-info:before{
	display: table;
    content: " ";
}
main.delivery .one-tk-info:after{
	clear: both;
	display: table;
    content: " ";
}
main.delivery .one-tk-info + .one-tk-info{
	margin-top: 64px;
}
main.delivery .tk-info h2{
	font-size: 16px;
  	font-weight: bold;
  	line-height: 1.31;
  	margin-top: 0;
  	margin-bottom: 5px;
}
main.delivery .tk-info img{
	max-width: 180px;
	margin-top: 9px;
}
main.delivery .tk-info p{
	line-height: 1.5;
	margin-bottom: 8px;
}
main.delivery .one-tk-desc + div p{
	margin-bottom: 0;
}
main.delivery .tk-info p.name{
	font-size: 12px;
  	font-weight: bold;
  	margin-bottom: 0;
}
main.delivery .tk-info p + p.name{
	margin-top: 8px;
}
main.delivery .tk-info a{
	line-height: 1.5;
	color: #000000;
}
main.delivery .tk-info a:hover{
	color: #000000;
}
main.delivery .tk-links div{
	margin-bottom: 3px;
}
main.delivery .delivery-info h2{
	font-size: 24px;
  	font-weight: bold;
  	line-height: 1.33;
  	color: #000000;
}
@media (min-width: 1200px){
	main.delivery .one-tk-desc{
		padding-right: 30px;
	}
	main.delivery .one-tk-desc + div,
	main.delivery .tk-links{
		margin-top: 26px;
	}
}
@media (max-width: 1199px){
	main.delivery h1{
		margin-top: 35px;
	}
}
@media (max-width: 1199px) and (min-width: 768px){
	main.delivery .one-tk-desc{
		margin-bottom: 2px;
	}
}
@media (min-width: 768px){
	main.delivery .one-tk-info > div:first-child{
		text-align: center;
	}
}
@media (max-width: 767px){
	main.delivery h1{
		font-size: 24px;
		margin-bottom: 11px;
	}
	main.delivery p.useful-links{
		margin-top: 48px;
		margin-bottom: 3px;
	}
	main.delivery .tk-info img{
		margin-top: 0;
		margin-bottom: 16.3px;
	}
	main.delivery .one-tk-info{
		margin-bottom: 11px
	}
	main.delivery .tk-links{
		margin-top: 12px;
	}
}