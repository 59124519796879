/*main.unit.affix-buffer{
	margin-top: -35px;
}*/
.unit-wrapper{
	border: solid 1px #979797;
	height: 998px;
	overflow: hidden;
}
.unit-wrapper .unit-img{
	position: relative;
}
.detail-info-popup{
	background-color: #000000;
	width: 240px;
	position: absolute;
	padding: 10px 6px 22px 41px;
	z-index: 150;
	box-shadow: 5px 5px 0 0 rgba(0, 0, 0, 0.3);
}
.detail-info-popup:after  {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 115px;
    width: 0px;
    height: 0px;
    border-top: 10px solid #000000;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
}
.detail-info-popup a.name{
	font-size: 14px;
	font-weight: bold;
	line-height: 1.29;
	color: #ffffff;
	display: inline-block;
	margin-bottom: 7px;
	text-decoration: underline;
}
.detail-info-popup span.code{
	font-size: 14px;
	line-height: 1.29;
	color: #ffffff;
	display: block;
	margin-bottom: 8px;
}
.detail-info-popup span.number{
	font-size: 14px;
	font-weight: bold;
	line-height: 1.71;
	color: #ffffff;
	position: absolute;
	left: 16px;
	top: 8px;
}
.detail-info-popup .yellow-btn{
	margin-top: 0;
    border: none;
    font-size: 12px;
    line-height: 1;
    letter-spacing: 1px;
    padding: 6px 10px;
}
.detail-info-popup .in-cart{
	font-size: 12px;
	font-weight: 600;
	line-height: 1;
	letter-spacing: 1px;
	color: #ffffff;
	padding: 6px 10px;
	background-color: #00bc66;
	border:none;
	text-transform: uppercase;
}
.detail-info-popup .delete-cart{
	background: transparent;
	font-size: 0;
	border: none;
	background-image: url('../img/icon-price-delete.svg');
	height: 24px;
	width: 24px;
	vertical-align: top;
}
.detail-list h2{
	font-size: 24px;
	font-weight: bold;
	line-height: 1.33;
	color: #000000;
	margin-bottom: 13px;
	margin-top: 0;
}
.detail-list .call-tel-wrng{
	font-size: 14px;
	line-height: 1.5;
	color: #000000;
	position: relative;
	padding-left: 22px;
	margin-bottom: 25px;
}
.detail-list .call-tel-wrng:before{
	background-image: url('../img/icon-warning-red-16.svg');
	content: '';
	position: absolute;
	top: 3.5px;
	left: 0;
	width: 16px;
	height: 16px;
}
.detail-list .number{
	width: 36px;
	display: inline-block;
	vertical-align: top;
}
.detail-list .number .area-hilight{
	position: relative;
}
.detail-list .code{
	width: 160px;
}
.detail-list  .name{
	width: 250px;
}
.detail-list div.count{
	width: 120px;
}
main.catalog-model .detail-list-sidebar .ask-price{
	margin-bottom: 32px;
}
.detail-list-sidebar .row p{
	padding-left: 32px;
	position: relative;
	font-size: 14px;
	line-height: 1.5;
	color: #000000;
}
.detail-list-sidebar .row p:before{
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	height: 16px;
	width: 16px;
}
.detail-list-sidebar .row p.subscribe:before{
	background-image: url('../img/icon-percent-16.svg');
}
.detail-list-sidebar .row p.send:before{
	background-image: url('../img/icon-list-16.svg');
}
.detail-list-sidebar .row p.search:before{
	background-image: url('../img/icon-search-doc-16.svg');
}
.detail-list-sidebar .black-btn{
	border:none;
	font-size: 14px;
	font-weight: 600;
	line-height: 2.57;
	letter-spacing: 1.5px;
	padding: 0 16px;
	margin-left: 32px;
}
.detail-list-sidebar a{
	 margin-left: 32px;
	display: inline-block;
	color: #000000;
}
.area-hilight.green{
	background-color: #00bc66;
}
.area-hilight.green span{
	color: #ffffff;
}
@media (min-width: 1200px){
	.unit-wrapper{
		box-shadow: 5px 5px 0 0 rgba(0, 0, 0, 0.3);
	}
	.after-unit{
		margin-top: 69px;
	}
	.detail-list .table-header{
		margin-right: 180px;
	}
	.detail-list-sidebar{
		padding-left: 0;
	}
	.detail-list-sidebar .row > div + div{
		margin-top: 35px;
	}
}
@media (max-width: 1199px) and (min-width: 768px){
	.unit-wrapper{
    	height: 672px;
	}
	.unit-wrapper,
	.unit-wrapper + map + .after-unit .ask-price{
		margin-left: -48px;
    	margin-right: -48px;
	}
	.detail-list{
		margin-top: 65px;
	}
}
@media (max-width: 1199px){
	.unit-wrapper{
		margin-top: 35px;
	}
	main.catalog-model .after-unit .ask-price{
		margin-top: 0;
	}
	.detail-list .code{
		width: 148px;
	}
	.detail-list  .name{
		width: 295px;
	}
	.after-unit{
		display: flex;
		flex-direction: column;
	}
	.detail-list-sidebar{
		order: 1;
	}
	.detail-list{
		order: 2;
	}
	main.catalog-model .detail-list-sidebar .ask-price{
		margin-bottom: 46px;
	}
}
@media (max-width: 767px){
	.unit-wrapper{
    	height: 382px;
	}
	.unit-wrapper,
	.ask-price{
		margin-left: -18px;
    	margin-right: -18px;
	}
	.detail-list .number{
		display: none;
	}
	.detail-list-sidebar .row{
		display: none;
	}
	main.catalog-model .detail-list-sidebar .ask-price{
		margin-bottom: 52px;
	}
	.detail-list  .name{
		width: 100%;
	}
}