main.search{
	padding-bottom: 122px;
}
main.search h1{
	margin-top: 39px;
	margin-bottom: 22px;
}
.check-type{
	margin-top: 15px;
}
.table-header div,
.search-result .name-price,
.search-result .name-price div,
.detail-list .name-price,
.detail-list .name-price div{
	display: inline-block;
	vertical-align: top;
}
main.search .search-result{
	margin-top: 40px;
}
.search-result > p{
	font-size: 14px;
  	line-height: 1.5;
  	color: #000000;
  	margin-bottom: 12px;
}
.search-result .code{
	width: 150px;
}
.search-result .name{
	width: 200px;
}
.search-result .models{
	width: 210px;
}
.search-result div.count{
	width: 150px;
}
.search-result .table-header,
.detail-list .table-header{
	opacity: 0.9;
  	font-size: 16px;
  	font-weight: bold;
  	line-height: 1.5;
  	color: #000000;
  	border-bottom: 1px solid #b4c1c8;
  	margin-bottom: 10px;
}
.search-result .detail-line,
.search-result .detail-line a,
.detail-list .detail-line,
.detail-list .detail-line a,
.cart .detail-line,
.cart .detail-line a{
	opacity: 0.9;
	font-size: 16px;
  	line-height: 1.31;
  	color: #000000;
}
.search-result span.count,
.search-result span.count + span,
.detail-list span.count,
.detail-list span.count + span,
.cart span.count,
.cart span.count + span{
	font-size: 12px;
  	line-height: 1.75;
  	color: #888888;
}
.search-result span.count,
.detail-list span.count,
.cart span.count{
	display: inline-block;
	margin-left: 5px;
}
@media (min-width: 1200px){
	.search-result span.count,
	.search-result span.count + span,
	.detail-list span.count,
	.detail-list span.count + span{
		display: none;
	}
}
@media (max-width: 1199px){
	.search-result div.count,
	.detail-list div.count{
		display: none;
	}
	.search-result .code{
		width: 140px;
	}
	.search-result .name{
		width: 225px;
	}
	.search-result .models{
		width: 140px;
	}
	main.search #main-search-input{
		width: 80%;
	}
}
@media (max-width: 1199px) and (min-width: 992px){
	main.search .search-result .name {
	    width: 300px;
	}
	main.search .search-result .code {
	    width: 200px;
	}
	main.search .search-result .models{
		width: 260px;
	}
}
@media (max-width: 767px){
	main.search{
		padding-bottom: 119px;
	}
	main.search .search-result{
		margin-top: 32px;
	}
	.search-result > p{
		margin-bottom: 20px;
	}
	.table-header,
	.search-result .name-price .models{
		display: none;
	}
	.search-result .detail-line,
	.detail-list .detail-line{
		min-height: auto;
	}
	.search-result .detail-line a,
	.detail-list .detail-line a{
		font-size: 14px;
		font-weight: bold;
		line-height: 1.5;
	}
	.search-result .detail-line,
	.search-result .detail-line a,
	.detail-list .detail-line,
	.detail-list .detail-line a{
		opacity: 1;
	}
	.search-result .name-price,
	.detail-list .name-price{
		display: flex;
		flex-direction: column;
		width: 50%;
		margin-bottom: 3px;
	}
	.search-result .name {
	    width: 100%;
	}
	.search-result .name,
	.detail-list .name{
		order: 1;
	}
	.search-result .code,
	.detail-list .code{
		order: 2;
		margin-top: 3px;
	}
	.search-result .name,
	.search-result .code,
	.detail-list .name,
	.detail-list .code{
		width: auto;
	}
	.search-result .buy,
	.search-result .in-cart,
	.detail-list .buy,
	.detail-list .in-cart{
		top: 0;
	}
}