main.main{
	padding-top: 36px;
}
.main-search{
	padding: 28px 70px 113px 40px;
	background-image: url("../img/blocks-main.png"), linear-gradient(to bottom, #ffd35d, #ffc838);
	background-position: bottom right;
	background-repeat: no-repeat;
}
.main-search h2{
	font-size: 32px;
	font-weight: bold;
  	line-height: 1.25;
  	color: #111111;
  	margin-top: 0;
  	margin-bottom: 18px;
}
.main-search span{
	font-size: 12px;
	line-height: 1.25;
	display: inline-block;
}
.search-sample{
	cursor: pointer;
	border-bottom: dashed 1px rgba(0, 0, 0, 0.5);
}
.main-search p{
	font-size: 20px;
	line-height: 1.5;
	margin-top: 20px;
	margin-bottom: 0;
}
#main-search-input{
	width: 544px;
	height: 48px;
	background-color: #ffffff;
	border: solid 2px #000000;
	border-radius: 0;
	box-shadow: 3px 4px 0 0 rgba(0, 0, 0, 0.4);
	padding: 8px 10px 10px 36px;
	margin-bottom: 5px;
  	font-size: 18px;
  	line-height: 1.67;
}
.main-input-wrapper{
	position: relative;
}
.main-search-btn{
	width: 105px;
	height: 48px;	
	margin-left: -5px;
	margin-top: -4px;
}
.main-search-btn:hover,
.main-search-btn:focus{
	color: #ffffff;
}
main.main .black-btn{
	box-shadow: 4px 4px 0 0 rgba(0, 0, 0, 0.2);
}
.main-sales{
	background-image: url("../img/blocks__detail.png"), linear-gradient(to bottom, #ffd35d, #ffc838);
	background-color: #ffc838;
	background-repeat: no-repeat;
	background-position: right top;
	padding: 16px 24px 22px;
}
.main-sales h2{
	font-size: 24px;
  	font-weight: bold;
  	line-height: 1.33;
  	color: #000000;
  	margin: 0;
}
.main-sales p{
	font-size: 16px;
  	line-height: 1.5;
  	margin-bottom: 0;
}
#get-sale{
	position: relative;
	font-size: 14px;
	font-weight: 600;
	line-height: 2.57;
	letter-spacing: 1.5px;
	padding: 0 42px 0 16px;
	margin-top: 39px;
}
#get-sale:after{
	background-image: url("../img/icon-arrow-left-white.svg");
	content: "";
	position: absolute;
	height: 16px;
	width: 16px;
	top: 10px;
	right: 21px;
}
.sales-subscript{
	border: solid 4px #ffc93b;
	padding: 16px 24px 22px;
	margin-top: 30px;
}
.sales-subscript h2{
	font-size: 24px;
	font-weight: bold;
	line-height: 1.33;
	color: #000000;
	margin: 0;
}
.sales-subscript p{
	font-size: 14px;
  	line-height: 1.5;
  	margin-bottom: 0;
}
.tractor-models{
	margin-top: 30px;
	margin-right: 0;
	margin-left: 0;
}
.tractor-models .col-md-2 a{
	display: inline-block;
	border: solid 4px #ffc93b;
	height: 96px;
	width: 100%;
	padding-top: 16px;
	padding-left: 13px;
	background-position: center right;
	background-repeat: no-repeat;
	margin-bottom: 30px;
}
.tractor-models a.k-700a{
	background-image: url("../img/blocks-k-700-yellow.png");
}
.tractor-models a.k-701{
	background-image: url("../img/blocks-k-701-yellow.png");
}
.tractor-models a.k-702{
	background-image: url("../img/blocks-k-702-yellow.png");
}
.tractor-models a.k-744{
	background-image: url("../img/blocks-k-744-yellow.png");
}
.tractor-models a.k-703{
	background-image: url("../img/blocks-k-703-yellow.png");
}
.tractor-models a span{
	display: inline-block;
	line-height: 1;
	font-size: 32px;
  	font-weight: bold;
  	color: #000000;
  	border-bottom: solid 1px rgba(0, 0, 0, 0.3);
}
.tractor-models a:hover span{
	border-color: #000000;
}
.tractor-models h2{
	display: none;
	font-size: 14px;
	font-weight: bold;
	line-height: 1.5;
	color: #000000;
}
.second-text-block .col-lg-9 h2{
	font-size: 24px;
	font-weight: bold;
	line-height: 1.33;
	color: #000000;
	margin-top: 0;
	margin-bottom: 23px;
}
.second-text-block .col-lg-9 h2:nth-child(4){
	margin-top: 53px;
}
.second-text-block .col-lg-9 p{
	font-size: 16px;
  	line-height: 1.5;
  	opacity: 0.9;
}
.second-text-block .col-lg-3 h2{
	font-size: 16px;
	font-weight: bold;
	line-height: 1.5;
	color: #000000;
	margin-bottom: 2px;
}
.second-text-block .col-lg-3 a{
	font-size: 14px;
  	line-height: 1.5;
  	opacity: 0.9;
  	color: #000000;
  	border-bottom: solid 1px rgba(0, 0, 0, 0.3);
}
.second-text-block .col-lg-3 a:hover{
	border-color: #000000;
}
.second-text-block .col-lg-3 div.col-lg-12{
	margin-bottom: 9px;
}
.sales-subscript-form-wrapper{
	background-image: url("../img/pattern.svg");
	padding: 10px;
	margin-top: 103px;
}
.sales-subscript-form{
	padding-top: 43px;
	padding-bottom: 66px;
	background-color: #ffffff;
	margin-left: 0;
	margin-right: 0;
}
.sales-subscript-form .col-lg-8 h2{
	font-size: 24px;
  	font-weight: bold;
  	line-height: 1.33;
  	color: #000000;
  	margin-top: 0;
  	margin-bottom: 5px;
}
.sales-subscript-form .col-lg-8 p{
	font-size: 16px;
  	line-height: 1.5;
}
.sales-subscript-form .col-lg-8 h3{
	font-size: 16px;
	font-weight: bold;
	line-height: 1.5;
	margin-top: 23px;
	margin-bottom: 0;
	color: #111111;
}
form span.fieldname{
	font-size: 14px;
	font-weight: bold;
	line-height: 1.5;
	display: block;
}
form span.error{
	font-size: 12px;
	line-height: 1.75;
	color: #f52700;
	margin-top: 2px;
	display: block;
}
form input,
form textarea{
	border: solid 2px #ffc93b;
	border-radius: 0;
	font-size: 16px;
	line-height: 1.5;
	padding: 6px 12px;
}
form input:focus{
	box-shadow: 4px 4px 0 0 rgba(0, 0, 0, 0.2);
}
form input.error{
	border-color: #f62600;
}
form > span + span{
	margin-top: 14px;
}
form input,
form .yellow-btn{
	width: 240px;
}
form .yellow-btn{
	margin-top: 24px;
	margin-bottom: 12px;
}
form .yellow-btn + span{
	display: block;
	font-size: 12px;
	line-height: 1.25;
	color: #888888;
}
#sales-subscript-form h2.success{
	font-size: 24px;
	font-weight: 600;
	line-height: 1.33;
	color: #00bc66;
	position: relative;
	display: inline-block;
	margin-top: 0;
	margin-bottom: 11px;
}
h2.success:after{
	content: "";
	position: absolute;
	top: 0;
	right: -36px;
	width: 30px;
	height: 28px;
	background-image: url("../img/galka-success.svg");
}
.zapros h2.success:after{
	top: 5px;
	right: -36px;
}
#sales-subscript-form p{
	font-size: 16px;
  	line-height: 1.5;
  	color: #000000;
}
#recall textarea{
	resize: none;
	height: 84px;
	width: 280px;
}
#recall .not-requered{
	font-size: 12px;
	line-height: 1.25;
	text-align: left;
	color: #888888;
	display: block;
}
#recall .win.zapros h2{
	margin-bottom: 22px;
}
#send-recall{
	text-transform: uppercase;
}
@media (min-width: 1200px){
	.second-text-block{
		margin-top: 60px;
	}
	.second-text-block .col-lg-3{
		margin-top: 35px;
	}
	.sales-subscript-form .col-lg-8{
		padding: 0 50px;
	}
}
@media (min-width: 768px){
	.main-input-wrapper:before{
		content: "";
		position: absolute;
		top: 14px;
		left: 12px;
		height: 16px;
		width: 16px;
		background-image: url("../img/icon-search-light-16.svg");
	}
	#recall .not-requered{
		margin-left: 125px;
	}
}
@media (max-width: 1199px) and (min-width: 768px){
	main.main{
		overflow-x: hidden;
	}
	main.main > .container:first-child:after{
		position: absolute;
		z-index: -1;
		content: "";
		opacity: 0.5;
		background-image: url(../img/engine__yellow.png);
		width: 776px;
		height: 591px;
		top: 0;
		right: -52%;
	}
	.sales-subscript-form-wrapper{
		margin-left: -48px;
    	margin-right: -48px;
	}
	.sales-subscript-form .col-lg-8{
		padding-left: 38px;
		padding-right: 38px;
	}
}
@media (max-width: 1199px){
	main.main .container  > .row > .col-lg-8{
		margin-bottom: 50px;
	}
	.main-search{
		background-image: none;
		padding: 0;
	}
	#main-search-input{
		box-shadow: 3px 4px 0 0 #888888;
		width: 84%;
	}
	.main-search h2{
		font-size: 36px;
		line-height: 1.22;
		margin-bottom: 34px;
	}
	.main-search p{
		margin-top: 13px;
	}
	.sales-subscript{
		margin-top: 0;
	}
	.main-search-btn{
		box-shadow: 4px 4px 0 0 #888888 !important;
	}
	.tractor-models a span{
		font-size: 27px;
	}
	.second-text-block{
		display: flex;
		flex-direction: column;
	}
	.second-text-block .col-lg-3{
		order: 1;
	}
	.second-text-block .col-lg-9{
		order: 2;
		margin-top: 65px;
	}
	.sales-subscript-form .col-lg-4{
		padding: 54px 50px 43px;
	}
	.main-sales,
	.sales-subscript{
		min-height: 195px;
	}
}
@media (max-width: 767px){
	main.main{
		padding-top: 16px;
	}
	.main .container .row:first-child{
		display: flex;
		flex-direction: column;
	}
	main.main .col-lg-8{
		padding-left: 15px;
		margin-bottom: 21px;
		order: 1;
	}
	main.main .col-lg-4{
		order: 3;
	}
	main.main .col-lg-4 .col-lg-12{
		padding-left: 15px !important;
		padding-right: 15px !important;
	}
	.main-search h2{
		font-size: 20px;
		line-height: 1.2;
		margin-bottom: 8px;
	}
	#main-search-input{
		padding-left: 16px;
		width: 80%;
	}
	.main-search-btn{
		font-size: 0;
		position: relative;
		width: 48px;
	}
	.main-search-btn:after{
		position: absolute;
		content: "";
		background-image: url("../img/icon-search-white-16.svg");
		height: 16px;
		width: 16px;
		right: 16px;
		top: 14px;
	}
	.main-sales{
		background-image: none;
		background-color: transparent;
		padding: 43px 0 22px;
	}
	#get-sale {
		margin-top: 18px;
	}
	.sales-subscript{
		border: none;
		padding: 16px 0 22px;
	}
	.tractor-models{
		margin: 0;
		order: 2;
	}
	.tractor-models h2{
		margin-top: 0;
		padding-left: 15px;
		display: block;
	}
	.tractor-models .col-md-2 a{
		border: none;
		background-image: none !important;
		height: auto;
		width: auto;
		padding: 0;
		margin-bottom: 12px;;
	}
	.tractor-models a span{
		font-size: 20px;
		line-height: 1.2;
	}
	.second-text-block .col-lg-3,
	.second-text-block .col-lg-9{
		padding-left: 15px;
	}
	.second-text-block .col-lg-9{
		margin-top: 55px;
	}
	.second-text-block .col-lg-9 h2{
		font-size: 20px;
		margin-bottom: 14px;
	}
	.sales-subscript-form-wrapper{
		padding-left: 0;
		padding-right: 0;
		margin-left: -18px;
		margin-right: -18px;
		margin-top: 78px;
	}
	.sales-subscript-form {
		padding-top: 30px;
		background-color: #fffaea;
	}
	.sales-subscript-form .col-lg-8 h2{
		font-size: 20px;
	}
	.sales-subscript-form .col-lg-8 p{
		font-size: 14px;
	}
	.sales-subscript-form .col-lg-8 h3{
		margin-top: 12px;
	}
	.sales-subscript-form .col-lg-4{
		padding: 0 15px;
	}
	#recall .win.zapros{
		min-height: 530px;
	}
	#recall textarea{
		width: 100%;
	}
}