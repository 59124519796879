main.about{
	padding-bottom: 90px;
}
main.about h1{
	margin-top: 40px;
	margin-bottom: 5px;
}
.tabs-headers{
	width: 420px;
}
.tabs-headers li{
	display: inline-block;
}
.tabs-headers li + li{
	margin-left: 16px;
}
.tabs-headers li a{
	color: #000000;
	font-size: 16px;
  	line-height: 2.13;
  	border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
.tabs-headers li a:hover{
	border-color: #000000;
}
.tabs-headers li.active a{
	color: rgba(0, 0, 0, 0.3);
	border-color: #ffc93b;
	border-width: 3px;
}
.tab-content{
	padding-top: 43px;
}
main.about .tab-content #about p,
main.about .tab-content #vacancy p{
	font-size: 16px;
	line-height: 1.5;
	margin-bottom: 0;
}
main.about #about .file-link .all-logos{
	margin-bottom: 20px;
}
main.about .tab-content #about p + p{
	margin-top: 24px;
}
main.about .tab-content .file-link .link-name{
	font-size: 14px;
  	line-height: 1.5;
  	color: #000000;
  	border-bottom: solid 1px rgba(0, 0, 0, 0.3);
}
main.about .tab-content .file-link .link-name:hover{
	border-color: #000000;
}
main.about .tab-content .file-link .logo-big{
	margin-bottom: 20px;
}
main.about .tab-content .file-link p{
	font-size: 12px !important;
  	line-height: 1.5;
}
main.about .tab-content .logo.zip,
main.about .tab-content .logo.pdf{
	margin: 5px 0;
}
main.about .tab-content ul{
	margin-bottom: 26px;
}
main.about .tab-content ul li{
	font-size: 16px;
	line-height: 1.31;
	position: relative;
	padding-left: 36px;
}
main.about .tab-content ul li:before{
	position: absolute;
	content: "";
	width: 14px;
	height: 1px;
	background-color: #111111;
	top: 10px;
	left: 12px;
}
main.about .tab-content ul li + li{
	margin-top: 11px;
}
main.about #vacancy ul li + li{
	margin-top: 8px;
}
main.about .tab-content #about h2{
	font-size: 24px;
  	font-weight: bold;
  	line-height: 1.33;
  	margin-top: 53px;
  	margin-bottom: 23px;
  	color: #000000;
}
main.about .tab-content #documents .file-link{
	width: 270px;
	display: inline-block;
	vertical-align: top;
}
main.about .tab-content #documents .col-lg-9 .file-link{
	margin-bottom: 68px;
	padding-right: 30px;
}
main.about .tab-content .file-link .logo-big{
	height: 180px;
	position: relative;
}
main.about .tab-content .file-link .logo-big a{
	position: absolute;
	bottom: 0;
	left: 0;
}
main.about .tab-content #documents .file-link .logo-mini{
	padding-left: 40px;
	position: relative;
}
main.about .tab-content #documents .file-link .logo-mini:before{
	content: "";
	position: absolute;
	background-image: url("../img/all-documents.svg");
	height: 32px;
	width: 32px;
	top: 4px;
    left: 0;
}
main.about .tab-content #documents .file-link img:not(.logos),
main.about .tab-content #requisites .file-link img:not(.logos){
	width: 128px;
	height: 100%;
	border: solid 1px #b2b2b2;
	box-shadow: 5px 5px 0 0 rgba(0, 0, 0, 0.3);
}
main.about .tab-content h2{
	font-size: 24px;
  	font-weight: bold;
  	line-height: 1.33;
  	color: #000000;
  	margin-top: 0;
  	margin-bottom: 23px;
}
main.about .tab-content p + h2{
	margin-top: 41px
}
main.about .tab-content #requisites p{
	margin-bottom: 12px;
	position: relative;
}
main.about .tab-content #requisites .col-lg-9{
	position: relative;
}
main.about .tab-content #requisites .col-lg-9 span:not(.logo):not(.size){
	font-size: 16px;
	line-height: 1.5;
	color: #000000;
}
main.about .tab-content #requisites span.req-name{
  	font-weight: bold;
  	width: 150px;
}
main.about .tab-content #requisites span.buffer{
	padding-bottom: 10px;
    display: block;
}
main.about .tab-content #requisites .col-lg-9 .requisites-md .link-name:nth-child(2){
	display: none;
}
main.about .tab-content #vacancy h2{
	margin-bottom: 14px;
}
main.about .tab-content #vacancy .cond{
	font-weight: bold;
	margin-bottom: 0;
}
main.about .tab-content .vacancy-contacts p:first-child{
	font-weight: bold;
}
main.about .tab-content .vacancy-contacts p:nth-child(2){
	margin-bottom: 25px;
}
main.about .tab-content .vacancy-contacts a,
main.about .tab-content .vacancy-contacts a:hover{
	color: #000000;
	line-height: 1.5;
}
@media (min-width: 1200px){
	main.about .tab-content .vacancy-contacts{
		padding-top: 10px;
	}
	main.about .tab-content .vacancy-contacts p{
		font-size: 14px !important;
	}
	main.about .tab-content #about .col-lg-9{
		padding-right: 100px;
	}
}
@media (min-width: 768px){
	main.about .tab-content #about,
	main.about .tab-content #requisites,
	main.about .tab-content #vacancy{
		margin-bottom: 68px;
	}
	main.about .tab-content #requisites p{
		margin-left: 174px;
	}
	main.about .tab-content #requisites span.req-name{
		position: absolute;
  		left: -174px;
	}
}
@media (max-width: 1199px){
	main.about{
		padding-bottom: 50px;
	}
	main.about h1{
		margin-top: 35px;
	}
	main.about .tab-content #documents .col-lg-9 .file-link{
		width: 49%;
	}
	main.about .tab-content #documents .file-link .logo-mini:before{
		right: 85px;
		left: auto;
	}
	main.about .tab-content #documents .col-lg-3 p,
	main.about .tab-content #requisites .col-lg-3{
		display: none;
	}
	main.about .tab-content #requisites .col-lg-9 .requisites-md{
		position: absolute;
		top: 5px;
		right: 15px;
	}
	main.about .tab-content #requisites .col-lg-9 .requisites-md .logo{
		margin-left: 8px;
	}
	main.about .tab-content #vacancy.active{
		display: flex;
		flex-direction: column-reverse;
	}
	main.about .tab-content .vacancy-contacts{
		margin-bottom: 34px;
	}
}
@media (max-width: 1199px) and (min-width: 768px){
	main.about .tab-content #documents .col-lg-3 .file-link{
		text-align: right;
		float: right;
		padding-right: 0;
	}
	main.about .tab-content #requisites .requisites-md > div{
		display: inline-block;
	}
	main.about .tab-content .vacancy-contacts p:nth-child(2),
	main.about .tab-content .vacancy-contacts p:first-child{
		margin-bottom: 0;
	}
}
@media (max-width: 767px){
	main.about{
		padding-bottom: 124px;
	}
	.tab-content{
		padding-top: 19px;
	}
	.scrollyeah{
		margin-left: -18px;
    	margin-right: -18px;
	}
	.tabs-headers{
		padding-left: 18px;
	}
	main.about .tab-content #about .logo-link{
		margin-top: 72px;
	}
	main.about #about .file-link .all-logos{
		margin-top: 62px;
	}
	main.about .tab-content #documents .col-lg-9 .file-link{
		width: auto;
		margin-bottom: 20px;
	}
	main.about .tab-content #documents .file-link .logo-big,
	main.about .tab-content .file-link p{
		display: none;
	}
	main.about .tab-content #documents .file-link .logo-mini{
		padding-left: 0;
	}
	main.about .tab-content #documents .file-link .logo-mini:before{
		display: none;
	}
	main.about .tab-content #requisites{
		padding-top: 60px;
	}
	main.about .tab-content #requisites .col-lg-9 .requisites-md{
		right: auto;
		left: 18px;
		top: -65px;
	}
	main.about .tab-content #requisites .col-lg-9 .requisites-md .logo{
		margin-left: 0;
	}
	main.about .tab-content #requisites .col-lg-9 .requisites-md .link-name:first-child{
		display: none;
	}
	main.about .tab-content #requisites .col-lg-9 .requisites-md .link-name:nth-child(2){
		display: inline-block;
	}
	main.about .tab-content #requisites span.req-name{
		display: block;
	}
	main.about .tab-content .vacancy-contacts p:first-child{
		display: none;
	}
}