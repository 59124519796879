footer .container{
	padding-bottom: 55px;
}
.grey-line{
	border-top: 2px solid #111111;
	padding-top: 40px;
	margin-left: 15px;
	margin-right: 15px;
}
footer .container *{
	font-size: 12px;
  	line-height: 2;
  	color: #000000;
}
ul.menu-bottom li{
	display: inline-block;
	margin-right: 12px;
}
ul.menu-bottom li a{
	border-bottom: solid 1px rgba(0, 0, 0, 0.3);
}
ul.menu-bottom li a:hover{
	border-color: #000000;
}
.footer-share-btns{
	display: inline-block;
	margin-top: 23px;
}
.footer-share-btns ul{
	display: inline-block;
}
.footer-share-btns ul li{
	display: inline-block;
	margin-left: 10px;
}
@media (min-width: 1200px){
	footer .container .footer-contacts{
		float: left;
		width: 33%;
	}
	footer .container .design-author{
		text-align: right;
	}
}
@media (max-width: 1199px){
	footer .container .design-author{
		margin-top: 24px;
	}
}
@media (max-width: 767px){
	footer .container .col-lg-5{
		margin-bottom: 25px;
	}
	.grey-line{
		margin-left: -3px;
		margin-right: -3px;
	}
}
