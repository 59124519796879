main.catalog-model{
	padding-top: 25px;
}
main.catalog-model h1{
	margin-top: 0;
	margin-bottom: 34px;
}
main.catalog-model .main-input-wrapper,
.model-catalog{
	display: inline-block;
	vertical-align: top;
}
.model-catalog{
	position: relative;
	padding-left: 40px;
	margin-left: 50px;
	margin-top: 5px;
}
.model-catalog:before{
	content: "";
	position: absolute;
	top: 5px;
	left: 0;
	width: 32px;
	height: 32px;
	background-image: url("../img/icon-search-document-32.svg");
}
.model-catalog .name{
	display: block;
	color: #000000;
}
.model-catalog span.logo{
	margin-left: 0;
}
.cat-names{
	padding-top: 35px;
	padding-bottom: 24px;
}
.cat-names:not(.category){
	border-bottom: 1px solid #e7f0f4;
}
.cat-names .main-name{
	font-size: 20px;
	font-weight: bold;
	line-height: 1.2;
	color: #000000;
}
.cat-names .subcat{
	margin-top: 11px;
}
.cat-names .subcat > div{
	margin-bottom: 8px;
}
.cat-names .subcat a{
	font-size: 14px;
	line-height: 1.5;
	color: #000000;
}
.discounts-cat > div{
	padding-right: 120px;
	padding-bottom: 4px;
}
.discounts-cat img{
	width: 65px;
}
.discounts-cat h2{
	margin-top: 16px;
	margin-bottom: 2px;
	font-size: 20px;
	font-weight: bold;
	line-height: 1.2;
	color: #000000;
}
.discounts-cat p{
	font-size: 15px;
	line-height: 1.6;
	color: #000000;
	margin-bottom: 18px;
}
.discounts-cat .yellow-btn{
	margin-top: 0;
	border: none;
}
.discounts-cat a.yellow-btn{
	display: inline-block;
}
.discounts-cat .black-btn{
	border: none;
	font-size: 14px;
  	font-weight: 600;
  	line-height: 2.57;
  	letter-spacing: 1.5px;
  	padding: 0 16px;
}
main.catalog-model .ask-price{
	margin-top: 23px;
	margin-bottom: 5px;
}
/*Каталог деталь*/
main.catalog-model h1 span{
	font-size: 24px;
	line-height: 1.33;
	color: #888888;
	font-weight: normal;
	display: inline-block;
	margin-left: 10px;
}
main.catalog-model p.uses{
	font-size: 16px;
	line-height: 1.5;
	text-align: left;
	color: #000000;
	margin-bottom: 17px;
}
div.uses + div.uses{
	padding-top: 31px;
}
div.uses h2{
	font-size: 24px;
	font-weight: bold;
	line-height: 1.33;
	color: #000000;
	margin-bottom: 12px;
	margin-top: 17px;
}
div.uses .cat-names:nth-child(2){
	padding-top: 0;
}
.ask-price-wrapper .ask-price,
.detail-list-sidebar .ask-price{
	width: auto;
	padding-bottom: 24px;
}
.ask-price-wrapper .ask-price p,
.detail-list-sidebar .ask-price p{
	width: auto;
}
.ask-price-wrapper .ask-price #ask-price,
.detail-list-sidebar .ask-price #ask-price{
	float: none;
}
.call-price{
	width: 273px;
	margin-bottom: 60px;
}
.call-price .price{
	font-size: 24px;
	font-weight: bold;
	line-height: 1.33;
	color: #000000;
	position: relative;
}
.call-price .price:after,
.call-price .price:before{
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	width: 73px;
	height: 2px;
	background-color: #f62600;
}
.call-price .price:after{
	transform: translate(-50%, -50%) rotate(15deg);
}
.call-price .price:before{
	transform: translate(-50%, -50%) rotate(-15deg);
}
.call-price .price-rubles{
	font-size: 16px;
	font-weight: bold;
	line-height: 1.5;
	color: #111111;
	display: inline-block;
	margin-left: 10px;
}
.call-price p{
	margin-top: 2px;
	margin-bottom: 3px;
	font-size: 14px;
	line-height: 1.5;
	color: #000000;
}
.call-price .buy{
	border: none;
}
.call-price .in-cart{
	font-size: 14px;
	font-weight: 600;
	line-height: 2.57;
	letter-spacing: 1.5px;
	color: #ffffff;
	padding: 0 16px;
	border: none;
	background-color: #00bc66;
	box-shadow: 4px 4px 0 0 rgba(0, 0, 0, 0.2);
	text-transform: uppercase;
	margin-top: 19px;
}
.call-price .delete-cart{
	font-size: 16px;
	line-height: 1.5;
	color: #f52700;
	background-color: transparent;
	border: none;
	margin-left: 28px;
}
/*Каталог модели без каталога*/
.model-catalog-big a.yellow-btn{
	display: inline-block;
	margin-top: 0;
	text-transform: uppercase;
	padding: 6px 20px
}
.model-catalog-big a.yellow-btn span{
	padding-left: 20px;
	position: relative;
}
.model-catalog-big a.yellow-btn span:before{
	content: "";
	position: absolute;
	top: 3.5px;
	left: 0;
	width: 12px;
	height: 13px;
	background-image: url("../img/icon-download.svg");
}
main.without-catalog .ask-price{
	margin-top: 30px;	
}
.without-catalog .sales-subscript-form-wrapper{
	margin-top: 68px;
}
@media (min-width: 1200px){
	main.catalog-model .main-input-wrapper{
		margin-bottom: 12px;
	}
	main.catalog-model .container{
		position: relative;
	}
	main.catalog-model:not(.unit) .container:after{
		position: absolute;
		z-index: -1;
		content: "";
		opacity: 0.5;
		background-image: url(../img/engine__yellow.png);
		width: 776px;
		height: 591px;
		top: 29px;
    	right: -43%;
	}
	.discounts-cat{
		padding-top: 57px;
	}
	.ask-price-wrapper .ask-price{
		margin-right: -15px;
		margin-left: -15px;
	}
}
@media (max-width: 1199px){
	.model-catalog{
		margin-left: 0;
		margin-top: 18px;
		margin-bottom: 8px;
	}
	main.catalog-model .main-input-wrapper{
		display: block;
	}
	.discounts-cat > div + div{
		margin-top: 50px;
	}
	.discounts-cat{
		padding-top: 30px;
	}
	.detail-info{
		display: flex;
		flex-direction: column;
	}
	.ask-price-wrapper{
		order: 1;
	}
	.applicability{
		order: 2;
	}
	.call-price{
		margin-bottom: 33px;
	}
}
@media (max-width: 1199px) and (min-width: 768px){
	.discounts-cat > div{
		padding-left: 120px;
		position: relative;
	}
	.discounts-cat img{
		position: absolute;
		top: 0;
		left: 0;
		width: 96px;
	}
}
@media (min-width: 768px){
	.model-catalog-big span.logo{
		margin-left: 24px;
	}
}
@media (max-width: 767px){
	main.catalog-model .ask-price{
		margin-left: -18px;
		margin-right: -18px;
	}
	.discounts-cat > div{
		padding-right: 0;
	}
	main.catalog-model h1 span{
		margin-left: 0;
		font-size: 14px;
  		line-height: 1.71;
  		display: block;
	}
	.model-catalog-big a.yellow-btn{
		margin-bottom: 16px;
	}
	.without-catalog .second-text-block .col-lg-9{
		margin-top: 42px;
	}
}