main.c404 p.c404{
	font-size: 256px;
  	font-weight: bold;
  	line-height: 1;
  	color: #ffc93b;
  	margin-top: 73px;
  	margin-bottom: 0;
}
main.c404 h1{
	color: #111111;
	font-weight: 600;
	margin-top: 7px;
}
main.c404 p:not(.c404){
	font-size: 16px;
  	line-height: 1.5;
  	color: #000000;
  	width: 60%;
  	margin: 0 auto 68px;
}
main.c404 .main-menu-desktop{
	margin-bottom: 100px;
}
@media (max-width: 1199px){
	main.c404 p:not(.c404){
		width: 100%;
	}
	main.c404 .main-menu-desktop{
		display: block;
		margin-bottom: 80px;
	}
	main.c404 .main-menu-desktop li + li{
		margin-left: 0;
	}
	main.c404 .row.main-menu-desktop li a{
	    padding: 0 5px 0;
	}
	.row.main-menu-desktop li a span{
	    font-size: 13px;
	}
}
@media (max-width: 767px){
	main.c404{
		padding-bottom: 0;
	}
	main.c404 p.c404{
		font-size: 128px;
		margin-top: 30px;
	}
	main.c404 h1{
		font-size: 20px;
		font-weight: bold;
		margin-top: 14px;
		margin-bottom: 4px;
	}
	main.c404 p:not(.c404){
		font-size: 14px;
		margin-bottom: 28px;
	}
	main.c404 .main-menu-desktop{
		margin-bottom: 84px;
	}
	main.c404 .row.main-menu-desktop li{
		display: list-item;
	}
	main.c404 .row.main-menu-desktop li:first-child a{
		margin-left: 0;
	}
	.row.main-menu-desktop li a span{
	    font-size: 16px;
	    line-height: 2.25;
	}
}