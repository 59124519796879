main.contacts h1{
	margin-top: 40px;
	margin-bottom: 20px;
}
main.contacts .col-sm-6 > div span,
main.contacts .col-sm-6 > div a{
	font-size: 16px;
	line-height: 1.5;
	color: #000000;
}
main.contacts .col-sm-6 > div span{
	display: block;
}
main.contacts .col-sm-6 > div span:first-child{
	font-weight: bold;
}
main.contacts .col-sm-6 > div + div{
	margin-top: 24px
}
main.contacts .yamap{
	padding-top: 53px;
	padding-bottom: 18px;
}
main.contacts h2{
	font-size: 24px;
  	font-weight: bold;
  	line-height: 1.33;
  	color: #000000;
}
main.contacts .yamap h2{
	display: inline-block;
  	margin: 0;
  	margin-right: 14px;
}
main.contacts .open-map-in-yandex{
	font-size: 14px;
  	line-height: 1.5;
  	color: #000000;
  	position: relative;
}
main.contacts .open-map-in-yandex:after{
	background-image: url("../img/icon-target.svg");
	content: "";
	position: absolute;
	top: 4px;
    right: -14px;
	width: 10px;
	height: 10px;
}
main.contacts .map-wrapper + .open-map-in-yandex{
	margin-top: 5px;
}
main.contacts .yamap .print-yandex-map{
	font-size: 14px;
  	line-height: 1.5;
  	color: #000000;
  	position: relative;
  	margin-top: 10px;
    display: inline-block;
}
main.contacts .yamap .print-yandex-map:hover{
	color: #000000;
}
main.contacts .yamap .print-yandex-map:before{
	background-image: url("../img/icon-print-16.svg");
	content: "";
	position: absolute;
	top: 4px;
    left: -24px;
	width: 16px;
	height: 16px;
}
main.contacts .yamap .zoom-control{
	display: inline-block;
	margin-left: 45px;
	cursor: pointer;
}
main.contacts .yamap .zoom-control span{
	font-size: 14px;
  	line-height: 1.5;
  	color: #000000;
}
main.contacts .yamap .zoom-control > div{
	height: 1px;
	width: 50px;
	background-color: #b4c1c8;
	position: relative;
	display: inline-block;
    margin: 0 1px 4px;
}
main.contacts .yamap .zoom-control > div:before{
	width: 16px;
  	height: 16px;
  	background-color: #000000;
  	border-radius: 100%;
  	content: "";
  	position: absolute;
  	top: -8px;
  	left: 0;
}
main.contacts .yamap .zoom-control.zoom-out > div:before{
	left: auto;
	right: 0;
}
main.contacts .yamap .left{
	float: left;
}
main.contacts .yamap .right{
	float: right;
}
main.contacts #map{
	width: 100%;
	height: 406px;
	border: solid 1px #000000;
	box-shadow: 4px 4px 0 0 rgba(0, 0, 0, 0.3);
}
main.contacts .map-wrapper{
	margin-right: 0;
	margin-left: 0;
}
.delivery-info{
	padding-top: 76px;
}
.delivery-info h2{
	margin-bottom: 25px;
}
.delivery-info .scrollyeah{
	margin-bottom: 24px;
}
.delivery-info ul{
	font-size: 0;
	width: 380px;
}
.delivery-info ul li{
	display: inline-block;
	font-size: 13px;
  	line-height: 24px;
  	height: 24px;
  	vertical-align: top;
  	padding: 0 10px;
  	border: solid 1px #ffc93b;
  	margin: 0;
  	cursor: pointer;
  	color: #000000;
  	box-sizing: border-box;
}
.delivery-info ul li:hover{
	background-color: #fff1cc;
}
.delivery-info ul li.active{
	background-color: #ffc93b;
	border: none;
}
.delivery-info ul li + li{
	border-left: none;
}
.delivery-info thead{
	border-bottom: 1px solid #b4c1c8;
}
.delivery-info thead tr{
	font-size: 14px;
  	font-weight: bold;
  	line-height: 1.5;
}
.delivery-info thead tr td{
	padding-bottom: 3px;
}
.delivery-info tbody tr td{
	font-size: 14px;
	line-height: 1.5;
}
.delivery-info tbody tr td:first-child{
	width: 30px;
  	font-weight: bold;  	
}
.delivery-info tr td:nth-child(2){
	padding-right: 34px;
}
.delivery-info tr td:nth-child(4){
	padding: 0 42px;
}
.delivery-info tbody tr td{
	padding-top: 3px;
}
.delivery-info tr.first-letter td{
	padding-top: 10px;
}
.delivery-info .delivery-xs{
	display: none;
}
@media (min-width: 768px){
	main.contacts .contacts-second{
		padding-top: 26px;
	}
	main.contacts .map-wrapper + .open-map-in-yandex{
		display: none;
	}
}
@media (max-width: 1199px){
	main.contacts .delivery-info{
		padding-top: 52px;
	}
	main.contacts .yamap .zoom-control{
		display: none;
	}
}
@media (max-width: 767px){
	main.contacts h1{
		margin-top: 35px;
		margin-bottom: 11px;
	}
	main.contacts .yamap{
		padding-bottom: 10px;
	}
	main.contacts #map{
		box-shadow: none;
	}
	main.contacts .map-wrapper{
		margin-right: -19px;
    	margin-left: -19px;
	}
	main.contacts .map-wrapper + .open-map-in-yandex{
		display: inline-block;
	}
	.delivery-info{
		padding-top: 63px;
	}
	.delivery-info ul{
		padding-left: 15px;
		padding-right: 15px;
	}
	.delivery-info h2{
		line-height: 1.2;
		font-size: 20px;
		margin-top: 0;
	}
	.delivery-info table{
		display: none;
	}
	.delivery-info .delivery-xs{
		display: block;
	}
	.delivery-xs > div{
		padding-bottom: 4px;
		border-bottom: 1px solid #e7f0f4;
	}
	.delivery-xs > div + div{
		margin-top: 19px;
	}
	.delivery-xs p{
		position: relative;
		padding-left: 135px;
		margin-bottom: 3px;
	}
	.delivery-xs span{
		font-size: 14px;
		line-height: 1.5;
		color: #000000;
	}
	.delivery-xs p span:first-child{
		position: absolute;
		left: 0;
		font-weight: bold;
	}
}