*{
	font-family: 'Open Sans', sans-serif;
	outline: none !important;
}
body{
	min-width: 280px;
	-webkit-font-smoothing: subpixel-antialiased;
	-moz-osx-font-smoothing: subpixel-antialiased;
}
header,
main,
footer{
	position: relative;
}
main{
	padding-bottom: 170px;
}
.overlay-back{
	position   : absolute;
    top        : 0;
    left       : 0;
    width      : 100%;
    height     : 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index    : 5;
    display    : none;
}
.overlay-back-popup{
	position   : absolute;
    top        : 0;
    left       : 0;
    width      : 100%;
    height     : 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index    : 500;
    display    : none;
}
a:hover,
a:focus{
	text-decoration: none;
	color: inherit;
}
.solid{
	border-bottom: 1px solid rgba(0,0,0,0.3);
}
.dashed{
	border-bottom: 1px dashed rgba(0,0,0,0.3);
}
.solid:hover,
.dashed:hover{
	border-color: #000000;
}
ul{
	list-style: none;
	padding: 0;
	margin: 0;
}
button:active{
	box-shadow: none !important;
}
input{
	box-sizing: border-box;
	-webkit-appearance: none;
	color: #000000;
}
input::placeholder{
	color: #888888;
}
h1{
	font-size: 36px;
	font-weight: bold;
  	line-height: 1.22;
  	color: #111111;
}
.yellow-btn{
	background-color: #ffc93b;
	box-shadow: 4px 4px 0 0 rgba(0, 0, 0, 0.2);
	padding: 0 16px 0 16px;
	margin-top: 19px;
	font-size: 14px;
	font-weight: 600;
	line-height: 2.57;
	letter-spacing: 1.5px;
	color: #000000;
	border-radius: 0;
	transition: all .2s ease-in;
}
.yellow-btn:focus{
	color: #000000;
}
.yellow-btn:hover:not([disabled]){
	background-color: #e6b434;
	color: #000000;
	transition: all .0s ease-out
}
.yellow-btn[disabled]{
	cursor: default;
	color: #000000;
}
.yellow-btn:active,
.black-btn:active{
	transform: translate(4px, 4px);
}
.black-btn{
	background-color: #000000;
	box-shadow: 4px 4px 0 0 rgba(0, 0, 0, 0.4);
	border-radius: 0;
	font-size: 16px;
	font-weight: 600;
	line-height: 2.25;
	letter-spacing: 2px;
	color: #ffffff;
	transition: all .2s ease-in
}
.black-btn:hover{
	background-color: #333333;
	color: #ffffff;
	transition: all .0s ease-out
}
.black-btn:focus{
	color: #ffffff;
}
span.logo.pdf,
span.logo.zip{
	display: inline-block;
	font-size: 11px;
	font-weight: bold;
	line-height: 14px;
	width: 30px;
	text-align: center;
	letter-spacing: 1.2px;
	opacity: 0.9;
	margin-left: 6px;
}
span.logo.pdf{
	background-color: #f62600;
	color: #ffffff;
}
span.logo.zip{
	background-color: #ffc93b;
	color: #000000;
}
span.size{
	font-size: 12px;
	color: #888888;
	margin-left: 4px;
}
.popup{
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 700;
	overflow-y: scroll;
}
.popup .win{
	background-color: #ffffff;
	position: relative;
	box-shadow: 10px 10px 0 0 rgba(0, 0, 0, 0.5);
}
.win button.closeBtn{
    position: absolute;
    top: 0;
    right: 0;
    border: 0;
    background-color: transparent;
}
input[type="checkbox"],
input[type="radio"] {
	display: none;
}
label.check{
	font-size: 0;
	margin-right: 16px;
}
label.check i {
	display: inline-block;
	height: 12px;
	width: 12px;
	position: relative;
	top: 1px;
	font-style: normal;
	color: #ccc;
	cursor: pointer;
	border: solid 2px #ffc93b;
}
label.check span {
	display: inline-block;
	margin-left: 7px;
	color: #000000;
	font-size: 14px;
  	line-height: 1.5;
  	font-weight: normal;
  	cursor: pointer;
}
label.check input[type="checkbox"] + i:before,
label.check input[type="radio"] + i:before{
	position: absolute;
	height: 12px;
	width: 12px;
	top: -4px;
    left: -1px;    
}
label.check input[type="checkbox"]:checked + i,
label.check input[type="radio"]:checked + i{
	position: relative;
	animation: icon-beat 0.1s ease;
}
label.check input[type="checkbox"]:checked + i:before,
label.check input[type="radio"]:checked + i:before{
	content: url("../img/checkbox-checked.svg");
}
.preloader{
	height: 36px;
	width: 36px;
	border-top: solid 4px #000000;
	border-right: solid 4px #000000;
	border-left: solid 4px #000000;
	border-bottom: solid 4px #ffc93b;
	border-radius: 100%;
	display: inline-block;
	position: relative;
	animation: spin 2s linear infinite;
}
.preloader + p{
	display: inline-block;
	font-size: 16px;
  	line-height: 1.5;
  	color: #000000;
  	margin-left: 14px;
  	vertical-align: top;
    margin-top: 8px;
}
@keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
}
.breadcrumbs{
	margin-bottom: 2px;
}
.breadcrumbs a:first-child{
	position: relative;
	padding-left: 15px;
}
.breadcrumbs a:first-child:before{
	content: "";
	position: absolute;
	top: 5px;
	left: 0;
	background-image: url("../img/icon-catalog-burger.svg");
	width: 11px;
	height: 10px;
}
.breadcrumbs a,
.breadcrumbs span{
	font-size: 14px;
  	line-height: 1.5;
}
.breadcrumbs .catalog-home{
	color: #000000;
}
.breadcrumbs span{
	display: inline-block;
	margin: 0 8px;
	color: #000000;
}
.breadcrumbs .model{
	color: rgba(0, 0, 0, 0.5);
}
/*Прилепающий футер*/
html {
    min-height: 100%;
    position: relative;
}
body {
    margin-bottom: 203px;
    /*Скрытие трактора*/
    overflow-x: hidden;
}
footer {
    bottom: 0;
    height: 203px;
    position: absolute;
    width: 100%;
}
@media (min-width: 1200px){
	.container{
		width: 1200px;
	}
	html header:after{
		transition: all 300ms;
	}
}
@media (min-width: 768px){
	.popup{
		position: fixed;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
@media (max-width: 992px) and (min-width: 768px){
	.container {
	    width: 768px;
	}
}
@media (max-width: 1199px){
	.container{
		padding-left: 48px;
		padding-right: 48px;
	}
	.wrapper {
	    margin-bottom: -241px;
	}
	footer, .wrapper:after {
	  height: 241px; 
	}
}
@media (max-width: 767px){
	h1{
		font-size: 24px;
		font-weight: bold;	
	  	line-height: 1.33;
	  	color: #000000;
	}
	main{
		padding-bottom: 135px;
	}
	html.limited,
	html.limited body{
		height: 100%;
		overflow: hidden;
	}
	.container{
		padding-left: 18px;
		padding-right: 18px;
	}
	.popup{
		position: absolute;
		display: block;
	}
	.popup .win{
		width: 100%;
		height: 100%;
	}
	.breadcrumbs{
		display: none;
	}
	body {
	    margin-bottom: 348px;
	}
	footer {
	    height: 348px;
	}
}