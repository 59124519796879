main.discounts h1{
	margin-top: 36px;
	margin-bottom: 22px;
}
main.discounts p{
	font-size: 16px;
	line-height: 1.5;
	text-align: left;
}
main.discounts .desc > p{
	margin-bottom: 46px;
}
main.discounts .data h3{
	font-size: 20px;
  	font-weight: bold;
  	line-height: 1.2;
  	color: #000000;
  	margin-top: 0;
	margin-bottom: 14px;
}
.detail-line{
	position: relative;
}
.detail-line + .detail-line{
	margin-top: 12px;
}
main.discounts .data + .data{
	margin-top: 34px;
}
main.discounts .data .name-price{
	position: relative;
	font-size: 16px;
  	line-height: 1.5;
  	display: inline-block;
    width: 600px;
}
main.discounts .data .dotted{
	position: absolute;
	bottom: 5px;
	left: 0;
	width: 100%;
	/*border-bottom: 2px dotted #888888;*/
	background-image: linear-gradient(to right, #888888 30%, rgba(255,255,255,0) 0%);
	background-position: bottom;
	background-repeat: repeat-x;
	background-size: 6px 2px;
	height: 2px;
	z-index: 0;
}
main.discounts .data .name{
	position: relative;
	display: inline;
	max-width: 530px;
	padding-right: 5px;
}
main.discounts .data .name,
main.discounts .data .price{	
	background-color: #ffffff;
	z-index: 1;
}
main.discounts .data .price{
	position: absolute;
	padding-left: 3px;
	padding-right: 13px;
	bottom: 0;
	right: 0;
	text-align: right;
}
main.discounts .data .price:after{
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	background-image: url("../img/rub-text.svg");
	height: 17.5px;
    width: 8px;
}
.detail-line .in-cart,
.detail-line .buy,
.detail-line .delete-cart{
	font-size: 14px;
	line-height: 1.5;
	margin-left: 20px;
	border: none;
	padding: 0 8px 3px;
}
.detail-line .buy{	
	color: #000000;
	background-color: #fff1cc;	
}
.detail-line .in-cart{
	color: #ffffff;
	background-color: #00bc66;
}
.detail-line .delete-cart{
	color: #f52700;
	background-color: #ffffff;
	padding-left: 0;
	padding-right: 0;
}
.subscribe-link h3{
	font-size: 16px;
	font-weight: bold;
  	line-height: 1.5;
  	margin-top: 38px;
}
.subscribe-link p{
	font-size: 14px;
  	line-height: 1.5;
  	margin-bottom: 15px;
}
#main-sale-subscript{
	padding: 0 16px;
	font-size: 14px;
  	font-weight: 600;
  	line-height: 2.57;
  	letter-spacing: 1.5px;
  	border: solid 2px #ffc93b;
  	background-color: transparent;
}
main.discounts .which-discount{
	margin-bottom: 138px;
}
@media (min-width: 1200px){
	main.discounts .second-text-block .col-lg-9{
		padding-right: 100px;
	}
}
@media (max-width: 1199px){
	main.discounts .desc > p{
		margin-bottom: 26px;
	}
	main.discounts .data .name-price{
		width: 470px;
	}
	main.discounts .data .name{
		max-width: 420px;
	}
	main.discounts .sales-subscript-form-wrapper{
		margin-top: 0;
	}
	main.discounts .which-discount {
	    margin-bottom: 95px;
	}
	main.discounts .subscribe-link{
		display: none;
	}
	main.discounts .second-text-block{
		padding-top: 40px;
	}
}
@media (max-width: 767px){
	.detail-line{
		min-height: 85px;
		padding-bottom: 16px;
		border-bottom: 1px solid #e7f0f4;
	}
	.detail-line + .detail-line{
		margin-top: 23px;
	}
	main.discounts .data .name-price{
		width: 100%;
	}
	main.discounts .data .dotted{
		display: none;
	}
	main.discounts .data .name{
		max-width: 50%;
		display: inline-block;
	}
	main.discounts .data .price{
		bottom: auto;
		top: 0;
		text-align: left;
	}
	.detail-line .price,
	.detail-line .buy,
	.detail-line .in-cart{
		right: 45% !important;
		transform: translateX(100%);
	}
	.detail-line .in-cart,
	.detail-line .buy{
		font-weight: 600;
  		line-height: 2.57;
  		letter-spacing: 1.5px;
  		width: 127px;
  		text-align: center;
  		text-transform: uppercase;
  		position: absolute;
		top: 30px;
	}
	.detail-line .delete-cart{
		display: none;
	}
}